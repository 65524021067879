import { MenuItem } from '@/components';
import { readFromLocalStorage, saveToLocalStorage } from '@/utils';
import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { FAVORITES_STORAGE_KEY } from './constants';
import { FavoritesContext } from './context';

const favorites: MenuItem = {
  icon: '',
  title: 'Favorite',
  url: '',
  children: [],
};

export const FavoritesProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const TabsParsed = readFromLocalStorage<MenuItem>(FAVORITES_STORAGE_KEY);
  const [favoritesTabs, setFavoritesTabs] = useState<MenuItem>(favorites);

  useEffect(() => {
    (async () => {
      try {
        if (TabsParsed?.children?.length === 0) {
          saveToLocalStorage(FAVORITES_STORAGE_KEY, favorites);
        } else {
          TabsParsed && setFavoritesTabs(TabsParsed);
        }
      } catch (e) {
        console.error(e);
      }
    })();
    // eslint-disable-next-line
  }, []);

  const addToFavorites = useCallback(
    async (item: MenuItem) => {
      try {
        // Check if item with the same title already exists in favoritesTabs.children
        const itemExists = favoritesTabs?.children?.find(
          (child) => child.title === item.title,
        );

        if (!itemExists) {
          const newItem = {
            ...favoritesTabs,
            children: [
              ...(favoritesTabs?.children || ([] as MenuItem[])),
              item,
            ],
          } as MenuItem;
          setFavoritesTabs(newItem);
        }
      } catch (e) {
        console.error(e);
      }
    },
    [favoritesTabs],
  );

  const removeFromFavorites = useCallback(async (item: MenuItem) => {
    try {
      setFavoritesTabs((prev) => {
        const temp = prev?.children?.filter((el) => el?.title !== item?.title);
        return {
          ...prev,
          children: temp,
        };
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        favoritesTabs &&
          saveToLocalStorage(FAVORITES_STORAGE_KEY, favoritesTabs);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [favoritesTabs]);

  return (
    <FavoritesContext.Provider
      value={{
        favorites: favoritesTabs ? favoritesTabs : ({} as MenuItem),
        addToFavorites,
        removeFromFavorites,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};
