import { DRAWER_WIDTH, HEADER_HEIGHT } from '@/utils';
import { AppBarProps, Box, BoxProps, AppBar as MuiAppBar, alpha, styled } from '@mui/material';
import { FC } from 'react';

interface CustAppBarProps extends AppBarProps {
  isDrawerOpen?: boolean;
}
const AppBar: FC<CustAppBarProps> = styled(MuiAppBar as FC<CustAppBarProps>, {
  shouldForwardProp: (prop: string) => prop !== 'isDrawerOpen',
})(({ theme, isDrawerOpen }) => ({
  display: 'flex',
  backgroundColor: theme.palette.background.paper,
  boxShadow: `${alpha(theme?.palette.primary.main, 0.1)} 0px 0px 20px 3px`,
  height: `${HEADER_HEIGHT}px`,
  alignitems: 'center',
  zIndex: theme.zIndex.drawer + 1,
  width: 'calc(100% - 73px)',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(isDrawerOpen && {
    display: 'flex',
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const HeaderControlsWrapper = styled(Box as FC<BoxProps>)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
}));


export { AppBar, HeaderControlsWrapper };

