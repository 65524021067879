import { FC, Fragment, useId } from 'react';
import { AppointmentBox, DayBox, DayWrapper, HourSlot } from './styled';
import { Text } from '@/components';
import { IDayProps } from './types';
import {
  getMinutesDifference,
  DAILY_HOURS,
  changeDateTime,
  mergeDateAndTime,
} from '@/utils';
import { TimeIndicator } from '../time-indicator/component';
import { Timeline } from '../timeline/component';
import {
  AppointmentPopover,
  useAppointmentPopover,
} from '../appointment-popover/component';
import { useSchedulerPropsContext } from '../../provider/context';

export const Day: FC<IDayProps> = ({ selectedDate, showHours }) => {
  const { appointments, onCreate } = useSchedulerPropsContext();
  const { appointmentPopoverProps, openAppointmentPopover } =
    useAppointmentPopover();
  const dayId = useId();
  const Wrapper = showHours ? DayWrapper : Fragment;
  const dayAppointments = appointments?.[selectedDate.split('T')[0]];

  return (
    <>
      <Wrapper>
        {showHours && <Timeline />}
        <DayBox showingHours={showHours}>
          {DAILY_HOURS.map((hour) => (
            <HourSlot
              onClick={() => onCreate?.(changeDateTime(selectedDate, hour))}
              key={`scheduler-hour-slot-${hour}-${dayId}`}
            />
          ))}
          {dayAppointments?.map((appointment) => (
            <AppointmentBox
              key={`scheduler-appointment-box-${appointment.date}-${appointment.startHours}`}
              startDate={mergeDateAndTime(
                appointment.date,
                appointment.startHours,
              )}
              duration={getMinutesDifference(
                mergeDateAndTime(appointment.date, appointment.startHours),
                mergeDateAndTime(appointment.date, appointment.endHours),
              )}
              onClick={(e) => openAppointmentPopover(e, appointment)}
            >
              <Text
                colorVariant="secondary"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
              >
                {appointment.description}
              </Text>
              <Text
                colorVariant="secondary"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
              >
                {appointment.type === 'appointment'
                  ? `${appointment.patient.firstName} ${appointment.patient.lastName}`
                  : 'Vacation'}
              </Text>
            </AppointmentBox>
          ))}
          <TimeIndicator selectedDate={selectedDate} />
        </DayBox>
      </Wrapper>
      <AppointmentPopover {...appointmentPopoverProps} />
    </>
  );
};
