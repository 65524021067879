import { Box, BoxProps, Button, ButtonProps, styled } from '@mui/material';
import { FC } from 'react';

export const UploadInput = styled('input')(() => ({
  display: 'none',
}));

export const FileButton = styled(Button as FC<ButtonProps>)(({ theme }) => ({
  '.MuiSvgIcon-root': {
    color: theme.palette.common.white,
  },
}));

export const UploaderContent = styled(Box as FC<BoxProps>)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: 'calc(100% - 40px)',
  overflowY: 'auto',
  margin: theme.spacing(2, 0, 0, 0),
}));
