import { FC, ReactNode, useState, useCallback } from 'react';
import { PatientsContext } from './context';
import { IPatient } from '@/types'; // Ensure this import path is correct
import { MOCK_PATIENT } from '@/mock'; // Ensure this import path is correct

export const PatientsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [patients, setPatients] = useState<IPatient[]>(MOCK_PATIENT);

  // Function to add a new patient
  const addPatient = useCallback((newPatient: IPatient) => {
    setPatients((prevPatients) => [...prevPatients, newPatient]);
  }, []);

  // Function to update an existing patient
  const updatePatient = useCallback((updatedPatient: IPatient) => {
    setPatients((prevPatients) =>
      prevPatients.map((patient) =>
        patient._id === updatedPatient._id ? updatedPatient : patient,
      ),
    );
  }, []);

  // Function to delete a patient
  const deletePatient = useCallback((id: string) => {
    setPatients((prevPatients) =>
      prevPatients.filter((patient) => patient._id !== id),
    );
  }, []);

  return (
    <PatientsContext.Provider
      value={{
        patients,
        setPatients,
        addPatient,
        updatePatient,
        deletePatient,
      }}
    >
      {children}
    </PatientsContext.Provider>
  );
};
