import {
  CreditCard,
  Dashboard,
  Group,
  Inventory,
  Person,
  PersonAdd,
  Quickreply,
  Settings,
  TodayTwoTone,
} from '@mui/icons-material';

/**
 * Define the type of values as JSX.Element with dinamyc @key
 *  */

interface MenuIcons {
  [key: string]: JSX.Element;
}

/**
 * Config the icon object to access it in the side menu
 * based on the @key property chosed in the @MenuItem type
 * from the menu tabs .json files
 *
 * Add new icons from https://mui.com/material-ui/material-icons/
 */

export const menuIcons: MenuIcons = {
  dashboard_icon: <Dashboard />,
  person_icon: <Person />,
  person_add_icon: <PersonAdd />,
  calendar_icon: <TodayTwoTone />,
  product_icon: <Inventory />,
  settings_icon: <Settings />,
  payment_icon: <CreditCard />,
  team_icon: <Group />,
  quick_icon: <Quickreply />,
};
