import { IconButton, Tooltip } from '@mui/material';
import { IAppointmentPopoverProps } from './types';
import { FC } from 'react';
import { Text } from '@/components';
import { StyledPopover, PopoverContentBox } from './styled';
import { CloseTwoTone, Edit, Delete } from '@mui/icons-material';
import { useSchedulerPropsContext } from '../../provider/context';

export const AppointmentPopover: FC<IAppointmentPopoverProps> = ({
  anchor,
  appointment,
  closeAppointmentPopover,
}) => {
  const { onUpdate, onDelete } = useSchedulerPropsContext();

  return (
    appointment && (
      <StyledPopover
        anchorEl={anchor}
        open={Boolean(appointment) && Boolean(anchor)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={closeAppointmentPopover}
      >
        <Tooltip title="Inchide">
          <IconButton onClick={closeAppointmentPopover}>
            <CloseTwoTone />
          </IconButton>
        </Tooltip>
        {onUpdate && (
          <Tooltip title="Editeaza">
            <IconButton onClick={() => onUpdate(appointment)}>
              <Edit />
            </IconButton>
          </Tooltip>
        )}
        {onDelete && (
          <Tooltip title="Sterge">
            <IconButton onClick={() => onDelete(appointment)}>
              <Delete color="error" />
            </IconButton>
          </Tooltip>
        )}
        <PopoverContentBox>
          <Text isBold>{appointment.description}</Text>
          <Text>
            {appointment.type === 'appointment'
              ? `${appointment.patient.firstName} ${appointment.patient.lastName}`
              : 'Vacation'}
          </Text>
          <Text>Incepe la: {appointment.startHours}</Text>
          <Text>Termina la: {appointment.endHours}</Text>
        </PopoverContentBox>
      </StyledPopover>
    )
  );
};

export { useAppointmentPopover } from './hooks';
