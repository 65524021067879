import { BoxProps } from '@mui/material';

export const extendedProps = [
  'column',
  'justifyCenter',
  'justifyEnd',
  'alignStart',
  'bgVariant',
  'paddingWrap',
];

export type BgVarType = 'default' | 'paper';

export interface WrapperProps extends Omit<BoxProps, 'display'> {
  /**
   * Applied for flex direction, as default row
   */
  column?: boolean;
  /**
   * Easier control of the layout
   */
  justifyCenter?: boolean;
  /**
   * Easier control of the layout
   */
  justifyEnd?: boolean;
  /**
   * Easier control of the layout
   */
  alignStart?: boolean;
  /**
   * Control the backgroundColor based on the theme variants for it.
   */
  bgVariant?: BgVarType;
  /**
   * Padding applyed to the wrapper, multiplying the default value of 8px,
   * for custom padding use the styling props from MUI Components.
   */
  paddingWrap?: number;
}
