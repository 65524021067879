import { IAppointment, IVacation } from '@/types';

export { type IAppointment };

export enum ViewMode {
  Day = 'daily',
  Week = 'weekly',
  Month = 'monthly',
}

export type AppointmentsDictionary = Record<
  string,
  (IAppointment | IVacation)[]
>;

export interface ISchedulerProps {
  /**
   * Appointments dictionary, if undefined an empty scheduler is shown
   */
  appointments?: AppointmentsDictionary;
  /**
   * Filterable categories, handle should be done frok outside the Scheduler component using the onChangeSelectedCategories prop
   */
  categories?: string[];
  /**
   * Callback called on categories selected change
   */
  onChangeSelectedCategories?: (selected: string[]) => void;
  /**
   * View mode selected by default
   */
  defaultViewMode?: ViewMode;
  /**
   * If true, a loader will be shown inside the scheduler
   */
  loading?: boolean;
  /**
   * Callback called when the Add button is pressed
   */
  onCreate?: (dateIso: string) => void;
  /**
   * Callback called when the Edit button on a appointment is pressed
   */
  onUpdate?: (appointment: IAppointment | IVacation) => void;
  /**
   * Callback called when the Delete button on a appointment is pressed
   */
  onDelete?: (appointment: IAppointment | IVacation) => void;
}
