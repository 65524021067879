import { Wrapper } from '@/components';
import { FC } from 'react';
import { ITeethSide } from '../assets/teeth';
import { ToothComponent } from './ToothComponent';

interface IProps {
  orientation: 'up' | 'down';
  teethSide: ITeethSide;
  handleSelectTooth: (
    id: string,
    category: 'notes' | 'files' | 'Intervention' | undefined,
  ) => void;
  handleRemoved: (medicalId: string) => void;
}

export const ToothSide: FC<IProps> = ({
  teethSide,
  orientation,
  handleSelectTooth,
  handleRemoved,
}) => {
  return (
    <Wrapper
      bgVariant="paper"
      sx={{
        gap: 0,
        flexDirection: teethSide?.side === 'left' ? 'row-reverse' : 'inherit',
        rotate:
          orientation === 'up'
            ? teethSide?.side === 'left'
              ? '-3deg'
              : '3deg'
            : teethSide?.side === 'left'
              ? '3deg'
              : '-3deg',
      }}
    >
      {teethSide.teethData?.map((tooth) => (
        <ToothComponent
          key={tooth.src}
          {...tooth}
          handleSelectTooth={handleSelectTooth}
          handleRemoved={handleRemoved}
        />
      ))}
    </Wrapper>
  );
};
