import { Language, Logout, SettingsSuggest } from '@mui/icons-material';

// Header menu data
export const headerMenuData = [
  {
    name: 'settings',
    icon: () => <SettingsSuggest />,
  },
  {
    name: 'logout',
    icon: () => <Logout />,
  },
  {
    name: 'language',
    icon: () => <Language />,
  },
];
