import { FC } from 'react';
import { IWeekHeaderProps } from './types';
import { Text } from '@/components';
import { formatDate, getDayNameFromISODate } from '@/utils';
import { HeaderBox, HeaderItemBox } from './styled';

export const WeekHeader: FC<IWeekHeaderProps> = ({ items }) => {
  return (
    <HeaderBox>
      {items.map(({ date }) => (
        <HeaderItemBox key={`scheduler-header-item-${date}`}>
          <Text sx={{ color: 'primary.main' }} isBold>
            {getDayNameFromISODate(date)}
          </Text>
          <Text variant="body1">{formatDate(date)}</Text>
        </HeaderItemBox>
      ))}
    </HeaderBox>
  );
};
