import { FC, memo } from 'react';
import { DAILY_HOURS } from '@/utils';
import { TimelineBox, TimelineHourBox, FullHeightDivider } from './styled';
import { Text } from '@/components';

export const Timeline: FC = memo(() => {
  return (
    <>
      <TimelineBox>
        {DAILY_HOURS.map((hour) => (
          <TimelineHourBox key={`scheduler-timeline-item-${hour}`}>
            <Text variant="body2" sx={{ p: 0 }}>
              {hour}
            </Text>
          </TimelineHourBox>
        ))}
      </TimelineBox>
      <FullHeightDivider flexItem orientation="vertical" variant="fullWidth" />
    </>
  );
});
