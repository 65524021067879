import { Box, BoxProps, styled } from '@mui/material';
import { FC } from 'react';

export interface ITeethImageComp extends BoxProps {
  toothWidth: number;
  toothUrl: string;
  selectedTooth: boolean;
  removed: boolean;
}

export const ToothImageComp: FC<ITeethImageComp> = styled(
  Box as FC<ITeethImageComp>,
  {
    shouldForwardProp: (prop) =>
      !['toothUrl', 'toothWidth', 'selectedTooth', 'removed'].includes(
        prop as string,
      ),
  },
)(({ toothUrl, toothWidth, selectedTooth, removed }) => ({
  height: 100,
  width: toothWidth,
  backgroundImage: `url(${toothUrl})`,
  backgroundRepeat: 'no-repeat',
  objectFit: 'cover',
  cursor: 'pointer',
  filter: selectedTooth ? 'sepia(100%)' : removed ? 'brightness(70%)' : 'unset',
  ':hover': {
    cursor: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='44' width='44'%3E%3Ccircle cx='12' cy='12' r='12' fill='%23ff0000'/%3E%3C/svg%3E"), auto`,
  },
}));
