import { Step } from 'react-joyride';

/**
 * Array with the steps that should be showed on first use
 */
export const steps: Step[] = [
  {
    target: '.first_component_to_focus',
    content: 'Message that should be showed on first component focus.',
  },
  {
    target: '.second_component_to_focus',
    content: 'Message that should be showed on second component focus.',
  },
];
