import React, { ReactNode } from 'react';

interface ListComponentProps<T> {
  items: T[];
  renderItem: (item: T, index: number) => ReactNode;
}

export const ListComponent = <T,>({
  items,
  renderItem,
}: ListComponentProps<T>) => {
  return <>{React.Children.toArray(items.map(renderItem))}</>;
};
