import {
  Box,
  TablePagination,
  capitalize,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ICustomTablePaginationProps } from './types';

export const CustomTablePagination: FC<ICustomTablePaginationProps> = ({
  totalRows,
  page,
  handleChangePage,
  rowsPerPage,
  handleChangeRowsPerPage,
  customComponent,
}) => {
  const { t } = useTranslation<string>();
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  if (customComponent) {
    return <Box sx={{ p: 0, m: 0, width: '100%' }}>{customComponent}</Box>;
  }

  return (
    <TablePagination
      component="div"
      labelRowsPerPage={capitalize(t('components.table.rows-per-page'))}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={matchDownSM ? [5] : [5, 10, 15]}
      count={totalRows}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      sx={{
        backgroundColor: '#fff',
        width: '100%',
        color: '#000',
      }}
    />
  );
};
