import {
  actions,
  notify,
  readFromLocalStorage,
  removeFromLocalStorage,
  request,
  saveToLocalStorage,
  useAppDispatch,
} from '@/utils';
import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { AUTH_USER_STORAGE_KEY } from './constants';
import { AuthContext } from './context';
import {
  AuthState,
  IUser,
  IUserCredentials,
  IUserSignIn,
  IValidateOtpData,
} from './types';

const initialState: IUser = {
  connection: 'offline',
  email: '',
  onboarding_completed: false,
  role: undefined,
  firstName: '',
  lastName: '',
};

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<IUser | undefined>(initialState);
  const [authState, setAuthState] = useState<AuthState>(AuthState.SignedOut);
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      try {
        setAuthState(AuthState.Loading);
        const userParsed = readFromLocalStorage<IUser>(AUTH_USER_STORAGE_KEY);
        if (userParsed) {
          setUser(userParsed);
          setAuthState(AuthState.SignedIn);
        } else setAuthState(AuthState.SignedOut);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const signIn = useCallback(async (data: IUserSignIn) => {
    try {
      setAuthState(AuthState.Loading);
      // call server to sign in
      const response = (await request.post('/auth/login', data)) as {
        user: IUser;
      };
      if (response) {
        setUser(response.user);
        setAuthState(AuthState.SignedIn);
        saveToLocalStorage(AUTH_USER_STORAGE_KEY, response.user);
        notify.success('Logged in successfully!');
      }
    } catch (e) {
      const error = e as unknown as any;
      if (error.isPaymentRequired) {
        setAuthState(AuthState.NotPaid);
      } else if (error.isNetworkError) {
        setAuthState(AuthState.NetworkError);
      } else {
        setAuthState(AuthState.SignedOut);
      }
      return Promise.reject(e);
    }
  }, []);

  const refreshAuthState = useCallback((authState: AuthState) => {
    setAuthState(authState);
    authState === AuthState.SignedOut && setUser(initialState);
  }, []);

  const activate = useCallback(
    async (data: IUserCredentials, onSuccess: (pass: string) => void) => {
      try {
        // call server to sign up
        const response = await new Promise((res) =>
          setTimeout(() => res(true), 500),
        );
        if (response) {
          dispatch(actions.activateNextStep());
          onSuccess && onSuccess(data.password);
        }
      } catch (e) {}
    },
    [dispatch],
  );

  const validateOtp = useCallback(
    async (data: IValidateOtpData, onSuccess?: () => void) => {
      try {
        setAuthState(AuthState.Loading);
        // call server to sign up
        const response = (await request.post('/auth/activate', data)) as {
          user: IUser;
        };
        if (response) {
          setUser(response.user);
          saveToLocalStorage(AUTH_USER_STORAGE_KEY, response.user);
          if (response.user.role === 'doctor' && onSuccess) {
            onSuccess();
          }
          setAuthState(AuthState.SignedIn);
        }
      } catch (e) {
        setAuthState(AuthState.SignedOut);
      }
    },
    [],
  );

  const signOut = useCallback(async () => {
    try {
      setAuthState(AuthState.Loading);
      // call server to sign out
      const response = await request.post('/auth/logout');
      if (response) {
        setUser(initialState);
        setAuthState(AuthState.SignedOut);
        removeFromLocalStorage(AUTH_USER_STORAGE_KEY);
      }
    } catch (e) {
      setAuthState(AuthState.SignedIn);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authState,
        user,
        signIn,
        activate,
        signOut,
        validateOtp,
        refreshAuthState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
