import { useState, MouseEvent } from 'react';
import {
  IAppointmentPopoverState,
  IUseAppointmentPopoverReturnType,
} from './types';
import { IAppointment } from '../../types';
import { IVacation } from '@/types';

const initialState = { anchor: null };

export const useAppointmentPopover = (): IUseAppointmentPopoverReturnType => {
  const [appointmentPopover, setAppointmentPopover] =
    useState<IAppointmentPopoverState>(initialState);
  const openAppointmentPopover = (
    e: MouseEvent,
    appointment: IAppointment | IVacation,
  ) =>
    setAppointmentPopover({
      anchor: e.currentTarget as HTMLElement,
      appointment,
    });
  const closeAppointmentPopover = () => setAppointmentPopover(initialState);

  return {
    appointmentPopoverProps: { ...appointmentPopover, closeAppointmentPopover },
    openAppointmentPopover,
  };
};
