import { Box, Popover, styled } from '@mui/material';

export const StyledPopover = styled(Popover)(({ theme }) => ({
  width: 300,
}));

export const PopoverContentBox = styled(Box)(({ theme }) => ({
  width: 300,
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  backgroudColor: theme.palette.grey[500],
}));
