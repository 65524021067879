import { Button, ButtonProps, styled } from '@mui/material';
import { FC } from 'react';

export const StyledButton = styled(Button as FC<ButtonProps>)(({ theme }) => ({
  width: '320px',
  height: '46px',
  padding: '0px 8px',
  boxSizing: 'border-box',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '20px',
  outline: 'none',
}));
