import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@mui/material';
import { StyledInput, StyledButton, Wrapper, CustomCard } from '@/components';
import { WrapperGrid } from '@/views/patients/add-patient/form-patient/index.styled';

// Define the types for the form fields
export interface InterventionFormValues {
  interventionId: string;
  interventionType: string;
  dentistAssigned: string;
  interventionStatus: string;
  productName: string;
  quantityRequired: number;
  category: string;
  unitPrice: number;
}

const interventionTypes = ['Cleaning', 'Extraction', 'Filling'];
const interventionStatuses = [
  'Planned',
  'In Progress',
  'Completed',
  'Cancelled',
];

const products = [
  { name: 'Product 1', category: 'Category 1', unitPrice: 10 },
  { name: 'Product 2', category: 'Category 2', unitPrice: 20 },
];

const dentists = ['Dr. Smith', 'Dr. Jones', 'Dr. Brown'];
const categories = Array.from(
  new Set(products.map((product) => product.category)),
);

interface InterventionFormProps {
  selectedToothId: string;
}

export const InterventionForm: React.FC<
  InterventionFormProps & {
    onAddIntervention: (data: InterventionFormValues) => void;
  }
> = ({ selectedToothId, onAddIntervention }) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm<InterventionFormValues>({
    mode: 'onChange',
  });
  const { t } = useTranslation<string>();
  const [selectedProduct, setSelectedProduct] = useState(products[0]);

  useEffect(() => {
    setValue('unitPrice', selectedProduct.unitPrice);
  }, [selectedProduct, setValue]);

  useEffect(() => {
    if (selectedToothId) {
      setValue('interventionId', selectedToothId);
    }
  }, [selectedToothId, setValue]);

  const productNameWatch = watch('productName');

  useEffect(() => {
    const product = products.find(
      (product) => product.name === productNameWatch,
    );
    if (product) setSelectedProduct(product);
  }, [productNameWatch]);

  const onSubmit = (data: InterventionFormValues) => {
    onAddIntervention(data);
  };

  return (
    <CustomCard width={'100%'} height={'100%'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <WrapperGrid container>
          <StyledInput
            {...register('interventionId', {
              required: 'ID-ul intervenției este obligatoriu',
            })}
            label={t('common.inputs.interventionId.label')}
            autoComplete="off"
            defaultValue={selectedToothId}
            error={!!errors.interventionId}
            helperText={errors.interventionId?.message}
            isDisabled={true}
            placeholder={t('common.inputs.interventionId.placeholder')}
          />

          <StyledInput
            {...register('interventionType')}
            select
            label={t('common.inputs.interventionType.label')}
            error={!!errors.interventionType}
            helperText={errors.interventionType?.message}
            defaultValue={interventionTypes[0]}
          >
            {interventionTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </StyledInput>
          <StyledInput
            {...register('dentistAssigned')}
            select
            label={t('common.inputs.dentistAssigned.label')}
            error={!!errors.dentistAssigned}
            helperText={errors.dentistAssigned?.message}
            defaultValue={dentists[0]}
          >
            {dentists.map((dentist) => (
              <MenuItem key={dentist} value={dentist}>
                {dentist}
              </MenuItem>
            ))}
          </StyledInput>

          <StyledInput
            {...register('interventionStatus')}
            select
            label={t('common.inputs.interventionStatus.label')}
            error={!!errors.interventionStatus}
            helperText={errors.interventionStatus?.message}
            defaultValue={interventionStatuses[0]}
          >
            {interventionStatuses.map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </StyledInput>
          <StyledInput
            {...register('productName', {
              required: 'Numele produsului este obligatoriu',
            })}
            select
            label={'Nume Produs'}
            error={!!errors.productName}
            helperText={errors.productName?.message}
            defaultValue={selectedProduct.name}
          >
            {products.map((product) => (
              <MenuItem key={product.name} value={product.name}>
                {product.name}
              </MenuItem>
            ))}
          </StyledInput>

          <StyledInput
            {...register('quantityRequired', {
              required: 'Cantitatea este obligatorie',
              min: 1,
            })}
            type="number"
            label={'Cantitate'}
            error={!!errors.quantityRequired}
            helperText={errors.quantityRequired?.message || 'Minimum 1'}
          />

          <StyledInput
            {...register('category')}
            select
            label={'Categorie'}
            error={!!errors.category}
            defaultValue={selectedProduct.category}
            disabled
          >
            {categories.map((category) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </StyledInput>

          <StyledInput
            {...register('unitPrice')}
            select
            label={'unitPrice'}
            error={!!errors.unitPrice}
            defaultValue={selectedProduct.unitPrice}
            disabled
          >
            {products.map((product) => (
              <MenuItem key={product.unitPrice} value={product.unitPrice}>
                {product.unitPrice}
              </MenuItem>
            ))}
          </StyledInput>

          <Wrapper justifyCenter mt={4}>
            <StyledButton variant="contained" type="submit" disabled={!isValid}>
              {t('common.inputs.interventionButton.label')}
            </StyledButton>
          </Wrapper>
        </WrapperGrid>
      </form>
    </CustomCard>
  );
};
