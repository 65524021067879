import { FC, ReactNode, useState, useCallback } from 'react';
import { ProductsContext } from './context';
import { IProduct } from '@/types';
import { MOCK_PRODUCT } from '@/mock';

export const ProductsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [products, setProducts] = useState<IProduct[]>(MOCK_PRODUCT);

  const addProduct = useCallback((newProduct: IProduct) => {
    setProducts((prevProduct) => [...prevProduct, newProduct]);
  }, []);

  const updateProduct = useCallback((updatedProduct: IProduct) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product._id === updatedProduct._id ? updatedProduct : product,
      ),
    );
  }, []);

  const getProductById = useCallback(
    (id: string): IProduct | undefined => {
      return products.find((product) => product._id === id);
    },
    [products],
  );

  const deleteProduct = useCallback((id: string) => {
    setProducts((prevProducts) =>
      prevProducts.filter((product) => product._id !== id),
    );
  }, []);

  return (
    <ProductsContext.Provider
      value={{
        products,
        setProducts,
        addProduct,
        updateProduct,
        deleteProduct,
        getProductById,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};
