import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileButton, UploadInput, UploaderContent } from './styled';
import { UploadedList } from './uploaded-list';

export interface IUploadFile {
  file: File;
  preview: string;
  status: 'loading' | 'success' | 'error';
}

export const Uploader: React.FC = () => {
  const { t } = useTranslation<string>();
  const [selectedFiles, setSelectedFiles] = useState<IUploadFile[]>([]);

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        const newFilesArray: IUploadFile[] = Array.from(event.target.files).map(
          (file) => ({
            file,
            preview: file.type.startsWith('image/')
              ? URL.createObjectURL(file)
              : '',
            status: 'loading',
          }),
        );

        setSelectedFiles((prevFiles) => [...prevFiles, ...newFilesArray]);
      }
    },
    [],
  );

  const deleteFile = (fileName: string) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file.file.name !== fileName),
    );
  };

  useEffect(() => {
    selectedFiles.forEach((file) => {
      if (file.status === 'loading') {
        uploadFile(file);
      }
    });
    // eslint-disable-next-line
  }, [selectedFiles]);

  const updateFileStatus = (
    fileName: string,
    status: 'loading' | 'success' | 'error',
  ) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.map((file) =>
        file.file.name === fileName ? { ...file, status: status } : file,
      ),
    );
  };
  /**
   * Tis will be moved and enhanced when the BE side is ready
   */
  const uploadFile = useCallback(async (uploadFile: IUploadFile) => {
    const formData = new FormData();
    formData.append('file', uploadFile.file);

    try {
      const response = await fetch('your-upload-url', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        updateFileStatus(uploadFile.file.name, 'success');
      } else {
        updateFileStatus(uploadFile.file.name, 'error');
      }
    } catch (error) {
      updateFileStatus(uploadFile.file.name, 'error');
    }
  }, []);

  return (
    <>
      <UploadInput
        accept="*/*"
        style={{ display: 'none' }}
        id="raised-button-file"
        multiple
        type="file"
        onChange={handleFileChange}
      />

      <label htmlFor="raised-button-file">
        <FileButton
          variant="contained"
          component="span"
          startIcon={<CloudUploadIcon />}
        >
          {t('common.add-documents')}
        </FileButton>
      </label>
      <UploaderContent>
        <UploadedList deleteFile={deleteFile} selectedFiles={selectedFiles} />
      </UploaderContent>
    </>
  );
};
