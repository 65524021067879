import { Card, Theme, alpha, styled } from '@mui/material';

export const StyledCard = styled(Card, {
  shouldForwardProp: (prop) =>
    !['hover', 'width', 'height'].includes(prop as string),
})(
  ({
    theme,
    hover,
    width,
    height,
  }: {
    theme: Theme;
    hover: boolean;
    width?: string | number;
    height?: string | number;
  }) => ({
    width: width ? width : theme?.spacing(50),
    height: height ? height : theme?.spacing(8),
    maxWidth: '100%',
    maxHeight: theme?.spacing(100),
    marginBottom: theme?.spacing(0),
    minHeight: theme?.spacing(8),
    minWidth: theme?.spacing(16),
    boxShadow: `${alpha(theme?.palette.primary.main, 0.1)} 0px 0px 20px 3px`,
    overflow: 'auto',
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: theme?.spacing(3),
    ...(hover && {
      ':hover': {
        boxShadow: `${alpha(
          theme?.palette.primary.main,
          0.5,
        )} 0px 0px 20px 3px`,
      },
    }),
  }),
);
