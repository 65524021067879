import { FC } from 'react';
import { IActionHeaderProps } from './types';
import { ActionHeaderWrapper, FlexBox } from './styled';
import { DatePickerDialog } from './date-picker-dialog/component';
import { FiltersSelect } from './filters-select/component';
import { ViewModeSelect } from './view-mode-select/component';
import { DateStepper } from './date-stepper/component';
import { CreateButton } from './create-button/component';
import { useSchedulerPropsContext } from '../../provider/context';

export const ActionHeader: FC<IActionHeaderProps> = ({
  currentViewMode,
  onViewModeChange,
  selectedDate,
  onSelectedDateChange,
}) => {
  const { categories, onChangeSelectedCategories, onCreate } =
    useSchedulerPropsContext();

  return (
    <ActionHeaderWrapper>
      <FlexBox gap={2}>
        <ViewModeSelect
          currentViewMode={currentViewMode}
          onViewModeChange={onViewModeChange}
        />
        {!!categories?.length && (
          <FiltersSelect
            availableCategories={categories}
            updateActive={onChangeSelectedCategories}
          />
        )}
        {onCreate && <CreateButton onClick={() => onCreate(selectedDate)} />}
      </FlexBox>
      <FlexBox>
        <DateStepper
          selectedDate={selectedDate}
          onSelectedDateChange={onSelectedDateChange}
          currentViewMode={currentViewMode}
        />
        <DatePickerDialog
          selectedDate={selectedDate}
          onSelectedDateChange={onSelectedDateChange}
        />
      </FlexBox>
    </ActionHeaderWrapper>
  );
};
