import { AddTwoTone } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { FC } from 'react';
import { ICreateButtonProps } from './types';

export const CreateButton: FC<ICreateButtonProps> = ({ onClick }) => {
  return (
    <Tooltip title="Creaza appointment">
      <IconButton onClick={onClick}>
        <AddTwoTone />
      </IconButton>
    </Tooltip>
  );
};
