import { PinToggle } from '@/components';
import { uuid } from '@/utils';
import { ListItem } from '@mui/material';
import React, { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { menuIcons } from '../data';
import { MenuCollapsed } from '../menu-collapsed';
import { MenuItem } from '../types';
import {
  StyledList,
  StyledListItemButton,
  StyledListItemIcon,
  StyledListItemText,
} from './styled';

interface MenuTabsProps {
  menuItems: MenuItem[];
  isDrawerOpen: boolean;
  favorites: MenuItem[];
  removeFromFavs: (item: MenuItem) => void;
  addToFavs: (item: MenuItem) => void;
}

const MenuTabs: FC<MenuTabsProps> = ({
  menuItems,
  isDrawerOpen,
  addToFavs,
  favorites,
  removeFromFavs,
}) => {
  const { t } = useTranslation<string>();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [listButtonHovered, setListButtonHovered] = useState('');

  return (
    <StyledList isDrawerOpen={isDrawerOpen}>
      {menuItems.map((item, index) => (
        <Fragment key={`side-menu-item-${item.title}-${index}`}>
          {item?.type === 'item' ? (
            <ListItem
              disablePadding
              sx={{ display: 'block' }}
              onClick={(): void => navigate(item?.url)}
              onMouseOver={(): void =>
                setListButtonHovered(`${item.url}-${index}`)
              }
              onMouseLeave={(): void => setListButtonHovered('')}
            >
              <StyledListItemButton isCurrent={pathname.includes(item.url)}>
                <StyledListItemIcon>
                  {menuIcons[item.icon] &&
                    React.cloneElement(menuIcons[item.icon], {
                      color: pathname.includes(item.url)
                        ? 'primary'
                        : 'inherit',
                    })}
                </StyledListItemIcon>
                {isDrawerOpen && (
                  <>
                    <StyledListItemText
                      primary={t(
                        `components.side-drawer.section-children.${item.title}`,
                      )}
                    />
                    {listButtonHovered === `${item.url}-${index}` && (
                      <PinToggle
                        pinned={Boolean(
                          favorites.find((child) => child.title === item.title),
                        )}
                        onClick={(): void =>
                          !!favorites.find(
                            (child) => child.title === item.title,
                          )
                            ? removeFromFavs(item)
                            : addToFavs(item)
                        }
                      />
                    )}
                  </>
                )}
              </StyledListItemButton>
            </ListItem>
          ) : (
            <Fragment key={uuid()}>
              <MenuCollapsed
                menuTab={item}
                favorites={favorites}
                addToFavs={addToFavs}
                removeFromFavs={removeFromFavs}
              />
            </Fragment>
          )}
        </Fragment>
      ))}
    </StyledList>
  );
};

export { MenuTabs };
