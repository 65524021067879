import { ToothFilesSelect, Wrapper } from '@/components';
import ToothNotesSelect from '@/components/tooth-notice';
import { FC, useMemo, useState } from 'react';
import {
  ITeethCollectionData,
  ITeethSide,
  teethCollection,
} from './assets/teeth';
import { TeethRow } from './components/TeethRow';
import { IToothNoteColection } from './hook/types';
import useTeethNotes from './hook/useTeethNotes';
import InterventionForm from '@/views/doctor/InterventionForm';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import InterventionTable from '@/views/doctor/InterventionForm/InterventionTable';
import { InterventionFormValues } from '@/views/doctor/InterventionForm/component';

export interface IDrawerState {
  open: boolean;
  category: 'notes' | 'files' | 'Intervention' | undefined;
}

export const drawerInitialState: IDrawerState = {
  open: false,
  category: undefined,
};

export const TeethSchema: FC = () => {
  const [selectedTooth, setSelectedTooth] = useState<string | null>(null);
  const { teethNotes, addNotes, deleteNote, editNote } =
    useTeethNotes(selectedTooth);

  const [teethData, setTeethData] =
    useState<ITeethCollectionData>(teethCollection);

  const [drawerOpen, setDrawerOpen] =
    useState<IDrawerState>(drawerInitialState);

  const selectedNote = useMemo(() => {
    const myNote = teethNotes.find((el) => el.medicalId === selectedTooth);
    return myNote || ({} as IToothNoteColection);
  }, [teethNotes, selectedTooth]);

  const handleSelectTooth = (
    id: string,
    category: 'notes' | 'files' | 'Intervention' | undefined,
  ) => {
    setSelectedTooth(id);
    setDrawerOpen({ open: true, category });
  };

  const handleRemoved = (medicalId: string) => {
    setTeethData((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState)); // Deep clone to avoid direct mutation
      const sides = [
        newState.upperTeeth.teethLeft,
        newState.upperTeeth.teethRight,
        newState.lowerTeeth.teethLeft,
        newState.lowerTeeth.teethRight,
      ];

      const searchAndToggle = (teethSide: ITeethSide) => {
        for (let tooth of teethSide.teethData) {
          if (tooth.medicalId === medicalId) {
            tooth.removed = !tooth.removed;
            return true;
          }
        }
        return false;
      };

      // Iterate over each side and stop when the tooth is found and updated
      for (let side of sides) {
        if (searchAndToggle(side)) {
          break;
        }
      }

      return newState;
    });
  };

  const handleCloseDialog = () => {
    setDrawerOpen({ ...drawerOpen, open: false });
  };
  const [interventions, setInterventions] = useState<InterventionFormValues[]>(
    [],
  );

  const onAddIntervention = (newIntervention: InterventionFormValues) => {
    setInterventions([...interventions, newIntervention]);
  };

  return (
    <>
      <Wrapper
        column
        justifyCenter
        sx={{ gap: 0 }}
        bgVariant="paper"
        height={300}
        maxWidth={750}
      >
        {Object.entries(teethData).map(([key, teethRow]) => (
          <TeethRow
            key={`teeth-row-${key}`}
            handleSelectTooth={handleSelectTooth}
            teethRow={teethRow}
            handleRemoved={handleRemoved}
          />
        ))}
      </Wrapper>
      {selectedTooth && drawerOpen.category === 'notes' && (
        <ToothNotesSelect
          notes={selectedNote?.notes || []}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          selectedToothId={selectedTooth}
          addNotes={addNotes}
          deleteNote={deleteNote}
          editNote={editNote}
        />
      )}
      {selectedTooth && drawerOpen.category === 'files' && (
        <ToothFilesSelect
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          selectedToothId={selectedTooth}
        />
      )}
      {selectedTooth && drawerOpen.category === 'Intervention' && (
        <Dialog
          open={drawerOpen.open}
          onClose={handleCloseDialog}
          aria-labelledby="dialog-title"
          sx={{ '& .MuiDialog-paper': { maxWidth: '900px' } }}
          onSubmit={handleCloseDialog}
        >
          <DialogTitle id="dialog-title">
            Intervenție
            <IconButton
              aria-label="close"
              onClick={handleCloseDialog}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            ></IconButton>
          </DialogTitle>
          <InterventionForm
            onAddIntervention={onAddIntervention}
            selectedToothId={selectedTooth}
          />
        </Dialog>
      )}
      <InterventionTable interventions={interventions} />
    </>
  );
};
