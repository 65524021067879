import teeth from './teeth.json';

export interface ITeethImage {
  src: string;
  medicalId: string;
  width: number;
  orientation: 'up' | 'down';
  removed: boolean;
}

export interface ITeethSide {
  side: 'left' | 'right';
  teethData: ITeethImage[];
}

export interface ITeethRow {
  rowOrientation: 'up' | 'down';
  teethRight: ITeethSide;
  teethLeft: ITeethSide;
}

export interface ITeethCollectionData {
  upperTeeth: ITeethRow;
  lowerTeeth: ITeethRow;
}

export const teethCollection: ITeethCollectionData = {
  upperTeeth: {
    rowOrientation: 'up',
    teethLeft: {
      side: 'left',
      teethData: [
        {
          orientation: 'up',
          medicalId: '11',
          src: teeth.incBUp,
          width: 45,
          removed: false,
        },
        {
          orientation: 'up',
          medicalId: '12',
          src: teeth.incUp,
          width: 45,
          removed: false,
        },
        {
          orientation: 'up',
          medicalId: '13',
          src: teeth.caninUp,
          width: 45,
          removed: false,
        },
        {
          orientation: 'up',
          medicalId: '14',
          src: teeth.preBUp,
          width: 50,
          removed: false,
        },
        {
          orientation: 'up',
          medicalId: '15',
          src: teeth.preUp,
          width: 50,
          removed: false,
        },
        {
          orientation: 'up',
          medicalId: '16',
          src: teeth.molarUp,
          width: 55,
          removed: false,
        },
        {
          orientation: 'up',
          medicalId: '17',
          src: teeth.molarBUp,
          width: 55,
          removed: false,
        },
        {
          orientation: 'up',
          medicalId: '18',
          src: teeth.molarCUp,
          width: 55,
          removed: false,
        },
      ],
    },
    teethRight: {
      side: 'right',
      teethData: [
        {
          orientation: 'up',
          medicalId: '21',
          src: teeth.incBUp,
          width: 45,
          removed: false,
        },
        {
          orientation: 'up',
          medicalId: '22',
          src: teeth.incUp,
          width: 45,
          removed: false,
        },
        {
          orientation: 'up',
          medicalId: '23',
          src: teeth.caninUp,
          width: 45,
          removed: false,
        },
        {
          orientation: 'up',
          medicalId: '24',
          src: teeth.preBUp,
          width: 50,
          removed: false,
        },
        {
          orientation: 'up',
          medicalId: '25',
          src: teeth.preUp,
          width: 50,
          removed: false,
        },
        {
          orientation: 'up',
          medicalId: '26',
          src: teeth.molarUp,
          width: 55,
          removed: false,
        },
        {
          orientation: 'up',
          medicalId: '27',
          src: teeth.molarBUp,
          width: 55,
          removed: false,
        },
        {
          orientation: 'up',
          medicalId: '28',
          src: teeth.molarCUp,
          width: 55,
          removed: false,
        },
      ],
    },
  },
  lowerTeeth: {
    rowOrientation: 'down',
    teethLeft: {
      side: 'left',
      teethData: [
        {
          orientation: 'down',
          medicalId: '41',
          src: teeth.incBDown,
          width: 45,
          removed: false,
        },
        {
          orientation: 'down',
          medicalId: '42',
          src: teeth.incDown,
          width: 45,
          removed: false,
        },
        {
          orientation: 'down',
          medicalId: '43',
          src: teeth.caninDown,
          width: 50,
          removed: false,
        },
        {
          orientation: 'down',
          medicalId: '44',
          src: teeth.preBDown,
          width: 50,
          removed: false,
        },
        {
          orientation: 'down',
          medicalId: '45',
          src: teeth.preDown,
          width: 50,
          removed: false,
        },
        {
          orientation: 'down',
          medicalId: '46',
          src: teeth.molarDown,
          width: 55,
          removed: false,
        },
        {
          orientation: 'down',
          medicalId: '47',
          src: teeth.molarBDown,
          width: 55,
          removed: false,
        },
        {
          orientation: 'down',
          medicalId: '48',
          src: teeth.molarCDown,
          width: 55,
          removed: false,
        },
      ],
    },
    teethRight: {
      side: 'right',
      teethData: [
        {
          orientation: 'down',
          medicalId: '31',
          src: teeth.incBDown,
          width: 45,
          removed: false,
        },
        {
          orientation: 'down',
          medicalId: '32',
          src: teeth.incDown,
          width: 45,
          removed: false,
        },
        {
          orientation: 'down',
          medicalId: '33',
          src: teeth.caninDown,
          width: 50,
          removed: false,
        },
        {
          orientation: 'down',
          medicalId: '34',
          src: teeth.preBDown,
          width: 50,
          removed: false,
        },
        {
          orientation: 'down',
          medicalId: '35',
          src: teeth.preDown,
          width: 50,
          removed: false,
        },
        {
          orientation: 'down',
          medicalId: '36',
          src: teeth.molarDown,
          width: 55,
          removed: false,
        },
        {
          orientation: 'down',
          medicalId: '37',
          src: teeth.molarBDown,
          width: 55,
          removed: false,
        },
        {
          orientation: 'down',
          medicalId: '38',
          src: teeth.molarCDown,
          width: 55,
          removed: false,
        },
      ],
    },
  },
};
