import { Box, styled } from '@mui/material';
import {
  SCHEDULER_HEADER_HEIGHT,
  HOUR_HEIGHT,
  TIMELINE_COLUMN_WIDTH,
} from '../../../constants';

export const HeaderBox = styled(Box)(({ theme }) => ({
  position: 'sticky',
  zIndex: 2,
  top: 0,
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: `${SCHEDULER_HEADER_HEIGHT}px`,
  paddingLeft: `${TIMELINE_COLUMN_WIDTH}px`,
  backgroundColor: theme.palette.background.paper,
  paddingTop: theme.spacing(1),
  paddingRight: '10px',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const HeaderItemBox = styled(Box)({
  width: '100%',
  height: `${HOUR_HEIGHT}px`,
  textAlign: 'center',
});
