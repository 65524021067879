import { createContext, useContext } from 'react';
import { IFavoriteContext } from './types';
import { MenuItem } from '@/components';

export const FavoritesContext = createContext<IFavoriteContext>({
  favorites: {} as MenuItem,
  addToFavorites: async (item: MenuItem) => {},
  removeFromFavorites: async (item: MenuItem) => {},
});

export const useFavoritesContext = (): IFavoriteContext => {
  return useContext(FavoritesContext);
};
