import { useFavoritesContext } from '@/providers/favorites/context';
import { actions, useAppDispatch, useAppSelector, uuid } from '@/utils';
import { Divider, Theme, useTheme } from '@mui/material';
import { FC, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '../text';
import { CommonRoutes } from './data';
import { DrawerHeader } from './drawer-header';
import { MenuTabs } from './menu-item';
import { Drawer, MenuWrapper } from './styled';
import { MenuItem, SideDrawerProps } from './types';

const SideDrawer: FC<SideDrawerProps> = ({ customTabs }) => {
  const { isDrawerOpen } = useAppSelector((state) => state.utils);
  const dispatch = useAppDispatch();
  const { t } = useTranslation<string>();
  const theme: Theme = useTheme();

  const handeToggle = () => {
    dispatch(actions.toggleDrawer(!isDrawerOpen));
  };

  const { favorites, addToFavorites, removeFromFavorites } =
    useFavoritesContext();

  const tabs: MenuItem[] = useMemo(
    () =>
      customTabs ? [favorites, ...CommonRoutes, ...customTabs] : CommonRoutes,
    [customTabs, favorites],
  );

  return (
    <Drawer variant="permanent" open={isDrawerOpen}>
      <DrawerHeader open={isDrawerOpen} closeDrawer={handeToggle} />
      <Divider />
      {/* ****************| MENU ACTIONS |************************* */}
      <MenuWrapper>
        {tabs.map(
          (el) =>
            (!!el.children?.length ||
              (el.title === 'favorite' && !!favorites?.children?.length)) && (
              <Fragment key={`menu-tabs-${uuid()}`}>
                {isDrawerOpen ? (
                  <Text
                    variant="caption"
                    marginLeft="20px"
                    sx={{ color: theme.palette.primary.main }}
                  >
                    {' '}
                    {t(
                      `components.side-drawer.sections-titles.${el.title.toLowerCase()}`,
                    ).toUpperCase()}{' '}
                  </Text>
                ) : (
                  <Divider sx={{ margin: '8px 0 0px 8px' }} />
                )}
                <MenuTabs
                  isDrawerOpen={isDrawerOpen}
                  favorites={favorites?.children || []}
                  removeFromFavs={removeFromFavorites}
                  addToFavs={addToFavorites}
                  menuItems={
                    el.title === 'Favorite'
                      ? favorites?.children || []
                      : el?.children || []
                  }
                />
              </Fragment>
            ),
        )}
      </MenuWrapper>
    </Drawer>
  );
};

export { SideDrawer };
