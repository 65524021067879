import { Skeleton, TableBody } from '@mui/material';
import { IColumn } from '../types';
import { FC, memo } from 'react';
import { StyledTableCell, StyledTableRow } from '../styled';

const TableCellFallback: FC = () => {
  return (
    <Skeleton animation="wave" variant="rectangular" width="100%" height={40} />
  );
};

const TableRowFallback: FC<{
  tableId: string;
  columns: IColumn[];
  rowIndex: number;
}> = ({ tableId, columns, rowIndex }) => {
  return (
    <StyledTableRow>
      {columns.map((col, i) => {
        return (
          <StyledTableCell
            sx={{ width: col.width }}
            align={col.align}
            key={`table-body-fallback-cell-${tableId}-${i}-${rowIndex}`}
          >
            <TableCellFallback />
          </StyledTableCell>
        );
      })}
    </StyledTableRow>
  );
};

export const TableBodyFallback: FC<{ tableId: string; columns: IColumn[] }> =
  memo(({ tableId, columns }) => {
    return (
      <TableBody>
        {new Array(5).fill('').map((_, i) => {
          return (
            <TableRowFallback
              rowIndex={i}
              tableId={tableId}
              columns={columns}
              key={`table-body-fallback-row-${tableId}-${i}`}
            />
          );
        })}
      </TableBody>
    );
  });
