import { SetStateAction, useMemo, useState } from 'react';
import { IToothNoteColection, ToothNote } from './types';
import { teethCollection } from '../assets/teeth';

export interface IuseTeethNotesReturnType {
  teethNotes: IToothNoteColection[];
  setTeethNotes: React.Dispatch<SetStateAction<IToothNoteColection[]>>;
  addNotes: (noteText: string) => void;
  deleteNote: (noteTimestamp: Date) => void;
  editNote: (noteTimestamp: Date, newText: string) => void;
}

function useTeethNotes(selectedTooth: string | null): IuseTeethNotesReturnType {
  const teethNoteColection: IToothNoteColection[] = useMemo(() => {
    // Flatten the teeth data into a single array
    const allTeeth = [
      ...teethCollection.upperTeeth.teethLeft.teethData,
      ...teethCollection.upperTeeth.teethRight.teethData,
      ...teethCollection.lowerTeeth.teethLeft.teethData,
      ...teethCollection.lowerTeeth.teethRight.teethData,
    ];

    const temp = allTeeth.map((tooth) => ({
      medicalId: tooth.medicalId,
      notes: undefined, // Initially, notes are undefined
    }));

    return temp;
  }, []);

  const [teethNotes, setTeethNotes] =
    useState<IToothNoteColection[]>(teethNoteColection);

  const addNotes = (noteText: string): void => {
    if (!selectedTooth) {
      return;
    }

    setTeethNotes((currentTeethNotes) => {
      const toothIndex = currentTeethNotes.findIndex(
        (t) => t.medicalId === selectedTooth,
      );
      if (toothIndex === -1) {
        return currentTeethNotes;
      }

      const newNote: ToothNote = {
        text: noteText,
        timestamp: new Date(),
      };

      // Clone the array and update the specific tooth's notes
      const updatedTeethNotes = [...currentTeethNotes];
      const currentNotes = updatedTeethNotes[toothIndex].notes || [];
      updatedTeethNotes[toothIndex] = {
        ...updatedTeethNotes[toothIndex],
        notes: [newNote, ...currentNotes],
      };

      return updatedTeethNotes;
    });
  };

  const deleteNote = (noteTimestamp: Date): void => {
    if (!selectedTooth) {
      return;
    }

    setTeethNotes((currentTeethNotes) => {
      const toothIndex = currentTeethNotes.findIndex(
        (t) => t.medicalId === selectedTooth,
      );
      if (toothIndex === -1) {
        return currentTeethNotes;
      }

      // Assuming timestamp uniqueness, filter out the note with the given timestamp
      const filteredNotes =
        currentTeethNotes[toothIndex].notes?.filter(
          (note) => note.timestamp.getTime() !== noteTimestamp.getTime(),
        ) || [];

      // Clone the array and update the specific tooth's notes without the deleted one
      const updatedTeethNotes = [...currentTeethNotes];
      updatedTeethNotes[toothIndex] = {
        ...updatedTeethNotes[toothIndex],
        notes: filteredNotes,
      };

      return updatedTeethNotes;
    });
  };

  const editNote = (noteTimestamp: Date, newText: string): void => {
    if (!selectedTooth) {
      return;
    }

    setTeethNotes((currentTeethNotes) => {
      const toothIndex = currentTeethNotes.findIndex(
        (t) => t.medicalId === selectedTooth,
      );
      if (toothIndex === -1) {
        return currentTeethNotes;
      }

      const updatedTeethNotes = [...currentTeethNotes];
      const updatedTooth = { ...updatedTeethNotes[toothIndex] };
      const updatedNotes = updatedTooth.notes ? [...updatedTooth.notes] : [];

      // Find the index of the note to be edited
      const noteIndex = updatedNotes.findIndex(
        (note) => note.timestamp.getTime() === noteTimestamp.getTime(),
      );
      if (noteIndex !== -1) {
        updatedNotes.splice(noteIndex, 1);
        const updatedNote = { text: newText, timestamp: new Date() };
        updatedNotes.unshift(updatedNote);

        updatedTooth.notes = updatedNotes;
        updatedTeethNotes[toothIndex] = updatedTooth;
      }

      return updatedTeethNotes;
    });
  };

  return {
    setTeethNotes,
    teethNotes,
    addNotes,
    deleteNote,
    editNote,
  };
}

export default useTeethNotes;
