import { AppointmentCategoryType } from '@/types';

export const DRAWER_WIDTH = 280;
export const HEADER_HEIGHT = 64;
export const MAX_WIDTH = 1550;
export const APPOINTMENT_CATEGORIES: AppointmentCategoryType[] = [
  'Ortodentie',
  'Chirurgie',
  'Control',
];
