import { Text } from '@/components';
import { Block, CheckCircle, Folder, Note } from '@mui/icons-material';
import { Box, Button, Popover, Stack } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ITeethImage } from '../assets/teeth';
import { ToothImageComp } from './styled';
import PendingActionsIcon from '@mui/icons-material/PendingActions';

interface IToothComponent extends ITeethImage {
  handleSelectTooth: (
    id: string,
    category: 'notes' | 'files'| "Intervention" | undefined,
  ) => void;
  handleRemoved: (medicalId: string) => void;
}

export const ToothComponent: FC<IToothComponent> = ({
  orientation,
  medicalId,
  src,
  width,
  handleSelectTooth,
  removed,
  handleRemoved,
}) => {
  const { t } = useTranslation<string>();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? medicalId : undefined;

  const handleNotes = (medId: string) => {
    handleSelectTooth(medId, 'notes');
    handleClose();
  };

  const handleFiles = (medId: string) => {
    handleSelectTooth(medId, 'files');
    handleClose();
  };

   const handleIntervention = (medId: string) => {
     handleSelectTooth(medId, 'Intervention');
     handleClose();
   };

  return (
    <>
      <Box
        sx={{
          width: `calc(${width}px - 8px)`,
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
        onClick={(event) => {
          handleClick(event);
        }}
      >
        {orientation === 'up' && <Text>{medicalId}</Text>}
        <ToothImageComp
          toothUrl={src}
          toothWidth={width}
          selectedTooth={id === medicalId}
          removed={removed}
        />
        {orientation === 'down' && <Text>{medicalId}</Text>}
      </Box>
      <Popover
        id={medicalId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <Stack direction="column" spacing={1} sx={{ p: 2 }}>
          <Text textAlign={'center'} fontSize={18} isBold>
            {t('common.tooth')}
            {medicalId}
          </Text>
          <Button
            startIcon={<Note sx={{ color: '#fff' }} />}
            onClick={() => handleNotes(medicalId)}
          >
            {t(
              'pages.patients.patient-profile.teeth-schema.actions.notes.title',
            )}
          </Button>
          <Button
            startIcon={<Folder sx={{ color: '#fff' }} />}
            onClick={() => handleFiles(medicalId)}
          >
            {t('pages.patients.patient-profile.teeth-schema.actions.files')}
          </Button>
          {removed ? (
            <Button
              startIcon={<CheckCircle sx={{ color: '#fff' }} />}
              onClick={() => handleRemoved(medicalId)}
            >
              {t('pages.patients.patient-profile.teeth-schema.actions.implant')}
            </Button>
          ) : (
            <Button
              startIcon={<Block sx={{ color: '#fff' }} />}
              onClick={() => handleRemoved(medicalId)}
            >
              {t('pages.patients.patient-profile.teeth-schema.actions.remove')}
            </Button>
          )}
          <Button
            startIcon={<PendingActionsIcon sx={{ color: '#fff' }} />}
            onClick={() => handleIntervention(medicalId)}
          >
            {t(
              'pages.patients.patient-profile.teeth-schema.actions.intervention',
            )}
          </Button>
        </Stack>
      </Popover>
    </>
  );
};
