import { Box, BoxProps, styled } from '@mui/material';
import { FC } from 'react';
import { CustomTextProps, Text } from '../text';

export const MemberWrapp: FC<BoxProps> = styled(Box as FC<BoxProps>)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '.MuiBox-root': {
      marginBottom: theme.spacing(1),
    },
  }),
);

export const CardTitle = styled(Text as FC<CustomTextProps>)(({ theme }) => ({
  width: '100%',
  fontSize: '24px',
  color: theme.palette.primary.main,
  textAlign: 'start',
  marginBottom: theme.spacing(1),
}));
