import {
  InputLabel,
  InputLabelProps,
  TextField,
  TextFieldProps,
  styled,
} from '@mui/material';
import { FC } from 'react';

export const CustomInputBase = styled(TextField as FC<TextFieldProps>)(
  ({ theme }) => ({
    width: '100%',
    minWidth: '300px',
    maxWidth: '600px',

    '.MuiInputBase-root': {
      height: '48px',
    },
    '.MuiFormLabel-root': {
      color: theme.palette.common.black,
    },
    '.MuiSvgIcon-root': {
      color: theme.palette.common.black,
    },
  }),
);

export type LabelColor = 'primary' | 'secondary' | 'disabled';

interface CustLabelProps extends InputLabelProps {
  labelColor: LabelColor;
}

export const StyledInputLabel = styled(InputLabel as FC<CustLabelProps>, {
  shouldForwardProp: (prop: string) => prop !== 'labelColor',
})(({ theme, labelColor = 'primary' }) => ({
  color: theme.palette.text[labelColor],
  display: 'flex',
}));
