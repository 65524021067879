import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, List, ListItem, ListItemText, TextField } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '../text';

interface Note {
  text: string;
  timestamp: Date;
}

interface NotesListContentProps {
  notesState: Note[];
  noteAction: {
    type: 'add' | 'edit' | null;
    index: number | null;
    note: string;
  };
  handleNoteChange: (note: string) => void;
  saveEdit: (editedText: string) => void;
  setNoteAction: (action: {
    type: 'add' | 'edit' | null;
    index: number | null;
    note: string;
  }) => void;
  startEdit: (index: number) => void;
  openDeleteDialog: (index: number) => void;
  addNotes: (noteText: string) => void;
}

export const NotesListContent: React.FC<NotesListContentProps> = ({
  notesState,
  noteAction,
  handleNoteChange,
  saveEdit,
  setNoteAction,
  startEdit,
  openDeleteDialog,
  addNotes,
}) => {
  const { t } = useTranslation<string>();
  return (
    <List>
      {noteAction.type !== 'edit' && (
        <ListItem sx={{ gap: 2 }}>
          <TextField
            variant="outlined"
            fullWidth
            value={noteAction.note}
            multiline
            onChange={(e) => handleNoteChange(e.target.value)}
          />
          <Button
            onClick={() => addNotes(noteAction.note)}
            variant="contained"
            disabled={noteAction.note?.length === 0}
          >
            {t('common.add')}
          </Button>
        </ListItem>
      )}
      {notesState.map((note, index) => (
        <ListItem key={index} style={{ gap: 2 }}>
          {noteAction.type === 'edit' && noteAction.index === index ? (
            <>
              <TextField
                variant="outlined"
                fullWidth
                multiline
                value={noteAction.note}
                onChange={(e) => handleNoteChange(e.target.value)}
              />
              <Button
                onClick={() => saveEdit(noteAction.note)}
                variant="contained"
                disabled={noteAction.note?.length === 0}
              >
                {t('common.save')}
              </Button>
              <Button
                onClick={() =>
                  setNoteAction({ type: null, index: null, note: '' })
                }
                variant="outlined"
              >
                {t('common.cancel')}
              </Button>
            </>
          ) : (
            <>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: '18px',
                }}
                primary={note.text}
                secondary={
                  <Text component="span">
                    {note.timestamp.toLocaleString('ro-RO', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                    })}
                  </Text>
                }
              />
              <Button onClick={() => startEdit(index)} variant="outlined">
                <EditIcon />
              </Button>
              <Button
                onClick={() => openDeleteDialog(index)}
                variant="outlined"
              >
                <DeleteIcon />
              </Button>
            </>
          )}
        </ListItem>
      ))}
    </List>
  );
};
