import {
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
  Theme,
} from '@mui/material';
import { FC, ReactNode, useEffect, useState } from 'react';
import { AuthState, useAuthContext } from '../auth';
import { themeBuilder } from './theme';
import { IThemePalette } from './types';

// To test the theme changes, we need to, complete values in object below
const testTheme: IThemePalette = {};

export const ThemeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [themeState, setThemeState] = useState<Theme>(themeBuilder());
  const { authState } = useAuthContext();

  useEffect(() => {
    (async () => {
      try {
        if (authState === AuthState.SignedIn) {
          const response: Theme = await new Promise((res) =>
            setTimeout(() => res(themeBuilder(testTheme)), 100),
          );
          if (response) {
            setThemeState(response);
          }
        } else if (authState === AuthState.SignedOut) {
          const buildedTheme = themeBuilder();
          buildedTheme && setThemeState(buildedTheme);
        }
      } catch (e) {}
    })();
  }, [authState]);

  return (
    <MuiThemeProvider theme={themeState}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};
