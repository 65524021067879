import { Box, Theme, useTheme } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '../text';
import { Wrapper } from '../wrapper';
import { IconWrap } from './styled';
import { IStatisticsCardProps } from './types';

export const StatisticsCard: FC<IStatisticsCardProps> = ({
  icon,
  detailsText,
  title,
  comparationText,
}) => {
  const theme: Theme = useTheme();
  const { t } = useTranslation<string>();

  return (
    <Wrapper width={'20%'}>
      <IconWrap>{icon}</IconWrap>
      <Box>
        <Text isBold variant="h5" sx={{ color: theme.palette.primary.main }}>
          {detailsText}
          {comparationText && (
            <Text component="span" colorVariant="disabled" sx={{ p: 0 }}>
              {comparationText}
            </Text>
          )}
        </Text>
        <Text>
          {t(
            title !== 'appointments'
              ? `pages.doctor.home.statistictics-card-section.${title}`
              : 'common.appointments',
          )}
        </Text>
      </Box>
    </Wrapper>
  );
};
