import { logo } from '@/assets';
import { selectors, useAppSelector } from '@/utils';
import { Toolbar } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Logo } from '../logo';
import HeaderMenu from './components/header-menu-component/component';
import { Notifications } from './components/notifications/component';
import UserInformation from './components/user-information/component';
import { AppBar, HeaderControlsWrapper } from './styled';

interface HeaderWrapperProps {
  isDrawerOpen: boolean;
  children?: ReactNode;
}

const HeaderWrapper: FC<HeaderWrapperProps> = ({ isDrawerOpen, children }) => (
  <AppBar position="fixed" isDrawerOpen={isDrawerOpen} sx={{ zIndex: 1 }}>
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      {children}
    </Toolbar>
  </AppBar>
);

HeaderWrapper.defaultProps = {
  children: '',
};

export const Header: FC = () => {
  const { isDrawerOpen } = useAppSelector(selectors.utils);

  return (
    <HeaderWrapper isDrawerOpen={isDrawerOpen}>
      <Logo height="100%" imgSrc={logo.logo} />
      <HeaderControlsWrapper>
        <Notifications />
        <UserInformation />
        <HeaderMenu />
      </HeaderControlsWrapper>
    </HeaderWrapper>
  );
};
