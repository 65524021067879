import { Box } from '@mui/material';

interface ILogoProps {
  width?: string;
  height?: string;
  imgSrc: string;
  altImg?: string;
}

export const Logo: React.FC<ILogoProps> = ({ width, height, imgSrc }) => {
  return (
    <Box
      component="img"
      src={imgSrc}
      width={width}
      height={height}
      alt="site-logo"
    />
  );
};
