import {
  List,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemIconProps,
  ListItemText,
  ListItemTextProps,
  ListProps,
  alpha,
  styled,
} from '@mui/material';
import { FC } from 'react';

interface CustomListProps extends ListProps {
  isDrawerOpen: boolean;
}

interface CustomListItemButtonProps extends ListItemButtonProps {
  isCurrent: boolean;
}

const StyledList: FC<CustomListProps> = styled(List as FC<CustomListProps>, {
  shouldForwardProp: (prop) => !['isDrawerOpen'].includes(prop as string),
})(({ theme, isDrawerOpen }) => ({
  overflow: 'hidden',
  paddingBottom: isDrawerOpen ? theme.spacing(1) : 0,
  color: theme.palette.text.secondary,
}));

const StyledListItemButton: FC<CustomListItemButtonProps> = styled(
  ListItemButton as FC<CustomListItemButtonProps>,
  {
    shouldForwardProp: (prop) => !['isCurrent'].includes(prop as string),
  },
)(({ theme, isCurrent }) => ({
  minHeight: '56px',
  justifyContent: 'initial',
  padding: theme.spacing(0, 1, 0, 0),
  margin: theme.spacing(0, 0, 0, 1),
  '.MuiSvgIcon-root': {
    color: isCurrent ? theme.palette.primary.main : 'inherit',
  },
  '.MuiListItemText-primary': {
    color: theme.palette.text.primary,
  },
  backgroundColor: isCurrent
    ? alpha(theme.palette.primary.light, 0.1)
    : 'inherit',
  '.fav_icon': {
    color: theme.palette.primary.main,
  },
}));

const StyledListItemIcon: FC<ListItemIconProps> = styled(
  ListItemIcon as FC<ListItemIconProps>,
)(({ theme }) => ({
  minWidth: 0,
  justifyContent: 'center',
  height: '56px',
  display: 'flex',
  alignItems: 'center',
  marginRight: theme.spacing(1.5),
  padding: theme.spacing(0, 2, 0, 3),
  color: theme.palette.text.primary,
}));

const StyledListItemText: FC<ListItemTextProps> = styled(
  ListItemText as FC<ListItemTextProps>,
)(() => ({
  '.MuiListItemText-primary': {
    fontSize: '16px',
    lineHeight: '28px',
  },
}));

export {
  StyledList,
  StyledListItemButton,
  StyledListItemIcon,
  StyledListItemText,
};
