import { Grid } from '@mui/material';
import { FC, useState } from 'react';
import { CustomCard } from '../card';
import {
  ActionHeader,
  Day,
  Month,
  SchedulerLoader,
  Sider,
  Week,
} from './components';
import { SchedulerPropsProvider } from './provider/provider';
import { ISchedulerProps, ViewMode } from './types';

const ViewModesCollection = {
  [ViewMode.Day]: Day,
  [ViewMode.Week]: Week,
  [ViewMode.Month]: Month,
};

export const Scheduler: FC<ISchedulerProps> = ({
  appointments,
  loading,
  categories,
  defaultViewMode = ViewMode.Day,
  onChangeSelectedCategories,
  onCreate,
  onUpdate,
  onDelete,
}) => {
  const [viewMode, setViewMode] = useState<ViewMode>(defaultViewMode);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString());
  const ViewSelected = ViewModesCollection[viewMode];

  return (
    <SchedulerPropsProvider
      propsValue={{
        appointments,
        loading,
        categories,
        onChangeSelectedCategories,
        onCreate,
        onUpdate,
        onDelete,
      }}
    >
      <Grid container>
        <Grid item sm={12}>
          <CustomCard height={700} width={'100%'}>
            <ActionHeader
              selectedDate={selectedDate}
              onSelectedDateChange={setSelectedDate}
              currentViewMode={viewMode}
              onViewModeChange={setViewMode}
            />
            {loading ? (
              <SchedulerLoader />
            ) : (
              <ViewSelected
                selectedDate={selectedDate}
                {...(viewMode === ViewMode.Day && { showHours: true })}
              />
            )}
          </CustomCard>
        </Grid>
        <Grid item mt={3} sm={12}>
          <CustomCard height={400} width={'100%'}>
            <Sider />
          </CustomCard>
        </Grid>
      </Grid>
    </SchedulerPropsProvider>
  );
};
