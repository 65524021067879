import {
  Box,
  BoxProps,
  Typography,
  TypographyProps,
  styled,
} from '@mui/material';
import { FC } from 'react';

export const IconWrap: FC<BoxProps> = styled(Box as FC<BoxProps>)(
  ({ theme }) => ({
    width: '66px',
    height: '66px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    '.MuiSvgIcon-root': {
      color: theme.palette.text.secondary,
      width: '36px',
      height: '36px',
    },
  }),
);

export const StatisticsText: FC<TypographyProps> = styled(
  Typography as FC<TypographyProps>,
)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '24px',
  color: theme.palette.primary.main,
  marginLeft: theme.spacing(1),
  '.distinct-text': {
    fontWeight: 400,
    fontSize: '18px',
    color: theme.palette.text.disabled,
  },
}));
