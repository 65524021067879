import { Divider, styled } from '@mui/material';
import { ICurrentTimeIndicatorProps } from './types';
import { FC } from 'react';
import { HOUR_HEIGHT } from '../../constants';
import {
  convertMinutesToPixel,
  getDayStartIso,
  getMinutesDifference,
} from '@/utils';

export const CurrentTimeIndicator = styled(
  Divider as FC<ICurrentTimeIndicatorProps>,
  {
    shouldForwardProp: (prop) =>
      !['currentTime', 'selectedDate'].includes(prop as string),
  },
)(({ currentTime, selectedDate, theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.error.main,
  position: 'absolute',
  height: 2,
  right: 0,
  top: convertMinutesToPixel(
    getMinutesDifference(getDayStartIso(currentTime), currentTime),
    HOUR_HEIGHT,
  ),
  opacity: currentTime.split('T')[0] !== selectedDate.split('T')[0] ? 0 : 1,
}));
