import { DRAWER_WIDTH } from '@/utils';
import {
  Box,
  BoxProps,
  CSSObject,
  DrawerProps,
  Drawer as MuiDrawer,
  Theme,
  Typography,
  TypographyProps,
  alpha,
  styled,
} from '@mui/material';
import { FC } from 'react';

const openedMixin = (theme: Theme): CSSObject => ({
  width: `${DRAWER_WIDTH}px`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  boxShadow: `${alpha(theme?.palette.primary.main, 0.1)} 0px 0px 20px 3px`,
  borderRight: 'none',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
  boxShadow: `rgba(150, 190, 238, 0.15) 0px 0px 20px 3px`,
  borderRight: 'none',
});

const Drawer: FC<DrawerProps> = styled(MuiDrawer, {
  shouldForwardProp: (prop: string) => prop !== 'open',
})(({ theme, open }) => ({
  width: `${DRAWER_WIDTH}px`,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const MenuWrapper: FC<BoxProps> = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  height: '100%',
  position: 'relative',
  paddingTop: '25px',
  '::-webkit-scrollbar': {
    width: '8px',
  },
  '::-webkit-scrollbar-thumb': {
    border: 'none',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: -20,
    left: 8,
    height: 'calc(100% + 20px)',
    width: '6px',
    zIndex: 2,
    backgroundColor: alpha(theme.palette.primary.light, 0.5),
  },
}));

const SectionTitle: FC<TypographyProps> = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.primary.main,
  fontSize: 12,
  padding: '0 20px',
  width: '100%',
}));

export { Drawer, MenuWrapper, SectionTitle };
