import { Text } from '@/components';
import { Button, Drawer, List, ListItem } from '@mui/material';
import React, { useState } from 'react';

interface DrwerRandomPorops {
  content: React.ReactNode;
  showButtonLabel?: string;
  additionalButton?: React.ReactNode;
  onAdditionalButtonClick?: () => void;
  isOpen?: boolean;
  onClose?: () => void;
  title?: string;
  isNotificationDrawer?: boolean;
}

export const CustomDrawer: React.FC<DrwerRandomPorops> = ({
  content,
  showButtonLabel,
  additionalButton,
  isOpen: controlledIsOpen,
  isNotificationDrawer,
  onClose,
  onAdditionalButtonClick,
  title,
}) => {
  const [internalIsOpen, setInternalIsOpen] = useState(false);
  const isDrawerOpen =
    controlledIsOpen !== undefined ? controlledIsOpen : internalIsOpen;

  const openDrawer = () => {
    setInternalIsOpen(true);
  };

  const closeDrawer = () => {
    setInternalIsOpen(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <div>
      {showButtonLabel && (
        <Button variant="contained" onClick={openDrawer}>
          {showButtonLabel}
        </Button>
      )}
      <Drawer anchor="right" open={isDrawerOpen} onClose={closeDrawer}>
        <List
          style={{
            width: isNotificationDrawer ? '400px' : '600px',
            margin: '10px',
          }}
        >
          {showButtonLabel && <Text variant="h4">{showButtonLabel}</Text>}
          {title && <Text variant="h4">{title}</Text>}
          {content}
          {additionalButton && <ListItem>{additionalButton}</ListItem>}
        </List>
      </Drawer>
    </div>
  );
};
