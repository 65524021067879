import {
  AuthProvider,
  ErrorBoundary,
  NotificationsProvider,
  StoreProvider,
  ThemeProvider,
} from '@/providers';
import { RoutesContainer } from '@/routes';
import { FC, Suspense } from 'react';
import './App.css';
import { AppointmentsProvider } from './providers/appointments';
import { FavoritesProvider } from './providers/favorites/provider';
import { PatientsProvider } from './providers/patients';
import { ProductsProvider } from './providers/products';

const App: FC = () => {
  return (
    <Suspense fallback="Loading...">
      <ErrorBoundary>
        <StoreProvider>
          <AuthProvider>
            <AppointmentsProvider>
              <PatientsProvider>
                <FavoritesProvider>
                  <ProductsProvider>
                    <ThemeProvider>
                      <NotificationsProvider />
                      <RoutesContainer />
                    </ThemeProvider>
                  </ProductsProvider>
                </FavoritesProvider>
              </PatientsProvider>
            </AppointmentsProvider>
          </AuthProvider>
        </StoreProvider>
      </ErrorBoundary>
    </Suspense>
  );
};

export default App;
