import { TypographyProps } from '@mui/material';
import React, { ReactNode } from 'react';
import StyledText from './styled';

export interface CustomTextProps extends TypographyProps {
  fullWidth?: string;
  spacing?: number;
  children: ReactNode;
  colorVariant?: 'primary' | 'secondary' | 'disabled';
  isBold?: boolean;
  fontSize?: number;
}

export const Text: React.FC<CustomTextProps> = ({
  fullWidth,
  spacing,
  colorVariant = 'primary',
  isBold,
  children,
  ...typographyProps
}: CustomTextProps) => {
  return (
    <StyledText
      fullWidth={fullWidth}
      spacing={spacing}
      colorVariant={colorVariant}
      isBold={isBold}
      {...typographyProps}
    >
      {children}
    </StyledText>
  );
};

Text.defaultProps = {
  fullWidth: 'auto',
  spacing: 0,
  colorVariant: 'primary',
  isBold: false,
};
