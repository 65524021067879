import CustomDrawer from '@/views/common/custom-drawer';
import {
  IDrawerState,
  drawerInitialState,
} from '@/views/patients/patient-profile/teeth-schema/component';
import React, { SetStateAction } from 'react';
import { Uploader } from '../uploader';

interface IToothFilesSelect {
  drawerOpen: IDrawerState;
  selectedToothId: string | null;
  setDrawerOpen: React.Dispatch<SetStateAction<IDrawerState>>;
}

export const ToothFilesSelect: React.FC<IToothFilesSelect> = ({
  selectedToothId,
  drawerOpen,
  setDrawerOpen,
}) => {
  const handleClose = (): void => {
    setDrawerOpen(drawerInitialState);
  };

  return (
    <>
      <CustomDrawer
        content={<Uploader />}
        isOpen={drawerOpen.open}
        onClose={handleClose}
        title={
          selectedToothId ? `Dinte ${selectedToothId}` : 'Selectați un dinte'
        }
      />
    </>
  );
};
