import { createSlice } from '@reduxjs/toolkit';
import { IUtilsSlice } from './types';

const initialState: IUtilsSlice = {
  count: 0,
  isDrawerOpen: true,
  language: 'ro',
};

const utilsSlice = createSlice({
  name: 'utils',
  initialState,
  reducers: {
    incrementCount(state) {
      state.count++;
    },
    toggleDrawer: (state, action) => {
      state.isDrawerOpen = action.payload;
    },
    changeLanguage: (state) => {
      state.language = state.language === 'ro' ? 'en' : 'ro';
    },
  },
});

export const utilsReducer = utilsSlice.reducer;
export const utilsActions = utilsSlice.actions;
