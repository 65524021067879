import { SansCaptionRegular } from '@/assets';
import {
  Palette,
  Theme,
  ThemeOptions,
  alpha,
  createTheme,
} from '@mui/material';
import { customTheme } from './constants';
import { IThemePalette } from './types';

/**
 *  Base theme without palette
 */
export const overRideTheme = (palette: Palette): ThemeOptions => {
  return {
    palette: palette,
    typography: {
      fontFamily: 'PT Sans Caption, sans-serif',
      body1: {
        color: palette.common.black,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
                @font-face {
                    font-family: 'sans-caption';
                    font-style: normal;
                    font-display: swap;
                    font-weight: 400;
                    src: local('PT Sans Caption'),
                        local('PTSansCaption-Regular'),
                        url(${SansCaptionRegular}) format('ttf');
                    unicodeRange: 
                        U+0000-00FF, U+0131, U+0152-0153,
                        U+02BB-02BC, U+02C6, U+02DA, U+02DC,
                        U+2000-206F, U+2074, U+20AC, U+2122,
                        U+2191, U+2193, U+2212, U+2215, U+FEFF;
                }
            `,
      },
      MuiButton: {
        defaultProps: {
          size: 'medium',
          disableRipple: true,
          disableElevation: true,
          variant: 'contained',
        },
        styleOverrides: {
          root: {
            fontWeight: 600,
            '&.Mui-disabled': {
              backgroundColor: `${palette.grey[400]}`,
              color: `${palette.grey[700]}`,
            },
          },
          outlined: {
            color: `${palette.primary.main}`,
            backgroundColor: `${palette.background.default}`,
            '&:hover': {
              color: `${palette.text.secondary}`,
              backgroundColor: `${palette.secondary.main}`,
            },
          },
        },
      },
      MuiFilledInput: {
        defaultProps: {
          margin: 'dense',
        },
      },
      MuiFormControl: {
        defaultProps: {
          margin: 'dense',
        },
      },
      MuiFormHelperText: {
        defaultProps: {
          margin: 'dense',
        },
      },
      MuiIconButton: {
        defaultProps: {
          size: 'medium',
          disableRipple: true,
        },
      },
      MuiInputBase: {
        defaultProps: {
          margin: 'dense',
          size: 'medium',
        },
        styleOverrides: {
          root: {
            backgroundColor: palette.common.white,
          },
          input: {
            color: palette.common.black,
            height: '12px',
          },
        },
      },
      MuiInputLabel: {
        defaultProps: {
          margin: 'dense',
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: palette.common.white,
          },
        },
      },
      MuiListItem: {
        defaultProps: {
          dense: true,
        },
      },
      MuiOutlinedInput: {
        defaultProps: {
          margin: 'dense',
        },
      },
      MuiFab: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiTable: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'outlined',
          margin: 'dense',
          size: 'small',
        },
      },
      MuiToolbar: {
        defaultProps: {
          variant: 'dense',
        },
      },
      MuiSvgIcon: {
        defaultProps: {
          color: 'primary',
          fontSize: 'medium',
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            boxShadow: `${alpha(palette.primary.main, 0.4)} 0px 0px 20px 3px`,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            '.MuiBackdrop-root': {
              backgroundColor: alpha(palette.grey[700], 0.4),
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: `${alpha(palette.primary.main, 0.5)} 0px 0px 20px 3px`,
          },
        },
      },
    },
    shape: {
      borderRadius: 8,
    },
  };
};

/**
 * Function that helps building the theme with parsed color palette
 * @param palette ThemeOptions palette type - palette of colours that needs to be used
 * @returns theme - Theme
 */
export function themeBuilder(themeParam?: IThemePalette): Theme {
  const customPalette = customTheme(themeParam);
  const theme = overRideTheme(customPalette.palette);
  return createTheme({
    ...theme,
  });
}
