import * as crypto from 'crypto-js';

/**
 * Function that helps encrypting data
 *
 * @param data unknown - data that needs to be encrypted
 * @param encryptionKey string - secure key for encryption
 * @returns string - representing encrypted strigified data
 */
export function encryptData(
  data: NonNullable<unknown>,
  encryptionKey: string,
): string {
  let stringifiedData: string;
  if (typeof data === 'string') stringifiedData = data;
  else stringifiedData = JSON.stringify(data);

  const encryptedData = crypto.AES.encrypt(
    stringifiedData,
    encryptionKey,
  ).toString();

  return encryptedData;
}

/**
 * Function that helps decrypting data
 *
 * @param encryptedData string - string representing data to be decrypted
 * @param encryptionKey string - secure key for decryption
 * @returns data decrypted of type T
 */
export function decryptData<T>(
  encryptedData: string,
  encryptionKey: string,
): T {
  const decryptedData = crypto.AES.decrypt(
    encryptedData,
    encryptionKey,
  ).toString(crypto.enc.Utf8);
  const parsedData = JSON.parse(decryptedData);

  return parsedData as T;
}

export const encryptURL = (plain: string, encryptionKey: string) => {
  const encrypted = crypto.AES.encrypt(`${plain}`, encryptionKey).toString();
  return encodeURIComponent(encrypted);
};
