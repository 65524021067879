import { ListComponent } from '@/components/data-map';
import { Text } from '@/components/text';
import { CloudDone } from '@mui/icons-material';
import { Avatar, TableBody, TableCell } from '@mui/material';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledTableCell, StyledTableRow } from '../styled';
import { TableBodyFallback } from './fallback';
import { ICustomTableBodyProps } from './types';

export const CustomTableBody: FC<ICustomTableBodyProps> = ({
  tableId,
  columns,
  rows,
  loading,
}) => {
  const { t } = useTranslation<string>();
  if (loading) {
    return <TableBodyFallback tableId={tableId} columns={columns} />;
  }

  if (!rows?.length) {
    return (
      <TableBody>
        <StyledTableRow>
          <TableCell
            colSpan={columns?.length}
            align="center"
            sx={{ background: '#fff', height: '300px' }}
          >
            <CloudDone fontSize="large" color="primary" />
            <Text color="secondary" variant="body1">
              {t('components.table.no-data')}
            </Text>
          </TableCell>
        </StyledTableRow>
      </TableBody>
    );
  }

  return (
    <TableBody>
      <ListComponent
        items={rows}
        renderItem={(row, _i) => (
          <StyledTableRow>
            <ListComponent
              items={columns}
              renderItem={(column, _index) =>
                column.key === 'avatar' ? (
                  <StyledTableCell
                    sx={{ width: column.width, paddingLeft: 5 }}
                    align={column.align}
                  >
                    <Avatar src={(row as {})[column.key as keyof typeof row]} />
                  </StyledTableCell>
                ) : (
                  <StyledTableCell
                    sx={{ width: column.width }}
                    align={column.align}
                  >
                    {(row as {})[column.key as keyof typeof row]}
                  </StyledTableCell>
                )
              }
            />
          </StyledTableRow>
        )}
      />
    </TableBody>
  );
};

export const MemoizedTableBody = memo(CustomTableBody);
