import { Box, styled } from '@mui/material';
import {
  HOUR_HEIGHT,
  TIMELINE_COLUMN_WIDTH,
  ACTION_HEADER_HEIGHT,
} from '../../constants';
import { IAppointmentBoxProps, IDayBoxProps } from './types';
import { FC } from 'react';
import {
  convertMinutesToPixel,
  getMinutesDifference,
  getDayStartIso,
} from '@/utils';

export const DayWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: `calc(100% - ${ACTION_HEADER_HEIGHT}px)`,
  overflowY: 'scroll',
});

export const DayBox = styled(Box as FC<IDayBoxProps>, {
  shouldForwardProp: (prop) => !['showingHours'].includes(prop as string),
})(({ showingHours }) => ({
  width: showingHours ? `calc(100% - ${TIMELINE_COLUMN_WIDTH}px)` : '100%',
  height: `${HOUR_HEIGHT * 24}px`,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
}));

export const HourSlot = styled(Box)(({ theme }) => ({
  width: '100%',
  height: `${HOUR_HEIGHT}px`,
  justifyContent: 'space-between',
  display: 'flex',
  flexDirection: 'row',
  borderTop: `1px solid ${theme.palette.grey[300]}`,
  ':hover': {
    backgroundColor: theme.palette.grey[300],
  },
}));

export const AppointmentBox = styled(Box as FC<IAppointmentBoxProps>, {
  shouldForwardProp: (prop) =>
    !['duration', 'startDate'].includes(prop as string),
})(({ duration, startDate, theme }) => ({
  height: convertMinutesToPixel(duration, HOUR_HEIGHT),
  backgroundColor: theme.palette.primary.light,
  boxShadow: theme.shadows[1],
  borderRadius: theme.spacing(1),
  position: 'absolute',
  top: convertMinutesToPixel(
    getMinutesDifference(getDayStartIso(startDate), startDate),
    HOUR_HEIGHT,
  ),
  width: '95%',
  left: '50%',
  transform: 'translate(-50%,0)',
  zIndex: 1,
  overflow: 'hidden',
  flexWrap: 'wrap',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));
