const LOCAL_STORAGE_ENCRYPT_KEY =
  process.env.REACT_APP_LOCAL_STORAGE_ENCRYPT_KEY;
const API_URL = String(process.env.REACT_APP_DOCTOR_API_URL);
const GUESTS_URL = String(process.env.REACT_APP_API_GUESTS_URL);

const CRYPTO_KEY = String(process.env.REACT_APP_CRYPTO_JS_SK);

const PERSIST_ENCRYPT_KEY = process.env.REACT_APP_PERSIST_ENCRYPT_KEY;

const API_ENCRYPT_KEY = process.env.REACT_APP_API_ENCRYPT_KEY;

const API_MAIN_URL = String(process.env.REACT_APP_MAIN_API_URL);

const APPLICATION_ID = process.env.REACT_APP_APPLICATION_ID;

const env_config = {
  encryption_local_storage: LOCAL_STORAGE_ENCRYPT_KEY,
  encryption_persist: PERSIST_ENCRYPT_KEY,
  encryption_api: API_ENCRYPT_KEY,
  application_id: APPLICATION_ID,
  crypto: {
    crypto_key: CRYPTO_KEY,
  },
  api: {
    url: API_URL,
    mainUrl: API_MAIN_URL,
    guestsUrl: GUESTS_URL,
  },
};

export default env_config;
