import { FC, useState, useEffect } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { IFiltersProps } from './types';

export const FiltersSelect: FC<IFiltersProps> = ({
  availableCategories,
  updateActive,
}) => {
  const [activeCategories, setActiveCategories] =
    useState<string[]>(availableCategories);

  const handleChangeActiveCategories = (e: SelectChangeEvent<string[]>) => {
    setActiveCategories(
      typeof e.target.value === 'string'
        ? e.target.value.split(', ')
        : e.target.value,
    );
  };

  useEffect(() => {
    updateActive?.(activeCategories);
    // eslint-disable-next-line
  }, [activeCategories]);

  return (
    <Box>
      <Select
        multiple
        value={activeCategories}
        onChange={handleChangeActiveCategories}
        renderValue={(value) => value.join(', ')}
      >
        {availableCategories.map((category) => (
          <MenuItem
            key={`scheduler-header-category-select-${category}`}
            value={category}
          >
            <FormControlLabel
              control={
                <Checkbox checked={activeCategories.includes(category)} />
              }
              label={category}
            />
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
