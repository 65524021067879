import { FC } from 'react';
import { IDeleteAppointmentDialogProps } from './types';
import { SimpleDialog, Text } from '@/components';

export const DeleteAppointmentDialog: FC<IDeleteAppointmentDialogProps> = ({
  appointment,
  open,
  onDismiss,
  onDelete,
}) => {
  return (
    <>
      <SimpleDialog
        open={typeof open === 'boolean' ? open : true}
        confirmButtonLabel="Delete"
        onSave={() => appointment && onDelete?.(appointment)}
        onClose={onDismiss}
      >
        {appointment?.type === 'appointment' ? (
          <>
            <Text>Are you sure to delete the following appointment?</Text>
            <Text>Date: {appointment?.date}</Text>
            <Text>Hour: {appointment?.startHours}</Text>
            <Text>
              Patient: {appointment?.patient.firstName}{' '}
              {appointment?.patient.lastName}
            </Text>
          </>
        ) : (
          <>
            <Text>Are you sure to delete the following vacancy?</Text>
            <Text>Date: {appointment?.date}</Text>

            <Text>Description: {appointment?.description}</Text>
            {!appointment?.allDay && (
              <>
                <Text>From hour: {appointment?.startHours}</Text>
                <Text>To hour: {appointment?.endHours}</Text>
              </>
            )}
          </>
        )}
      </SimpleDialog>
    </>
  );
};
