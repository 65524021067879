import { DRAWER_WIDTH, HEADER_HEIGHT } from '@/utils';
import { Box, BoxProps, styled } from '@mui/material';
import { FC } from 'react';

interface MainWrapperProps extends BoxProps {
  isDrawerOpen: boolean;
}

const MainWrapper: FC<MainWrapperProps> = styled(Box as FC<MainWrapperProps>, {
  shouldForwardProp: (prop: string) => prop !== 'isDrawerOpen',
})(({ theme, isDrawerOpen }) => ({
  marginLeft: '64px',
  padding: theme.spacing(3, 6),
  marginTop: `${HEADER_HEIGHT}px`,
  overflowY: 'auto',
  width: 'calc(100vw - 72px)',
  height: `calc(100vh - ${HEADER_HEIGHT}px)`,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(isDrawerOpen && {
    marginLeft: ` ${DRAWER_WIDTH}px`,
    width: `calc(100vw - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export { MainWrapper };
