import { UTC_WEEKLY_DAYS } from './constants';
import { DayOfWeek, IWeekStartEnd } from './types';

/**
 * Gets an array containing daily hours
 * @returns array of strings
 */
export function getDailyHoursArray(): string[] {
  const dailyHours = [];
  for (let i = 0; i <= 23; i++) {
    if (i < 10) dailyHours.push(`0${i}:00`);
    else dailyHours.push(`${i}:00`);
  }
  return dailyHours;
}

/**
 * Converts number of minutes to a pixel scale
 * @param minutes
 * @param hourHeight
 * @returns string
 */
export function convertMinutesToPixel(minutes: number, hourHeight: number) {
  const baseMinuteHeight = hourHeight / 60;
  return `${Math.floor(baseMinuteHeight * minutes)}px`;
}

/**
 * Gets minutes difference between two iso dates
 * @param startDateIso
 * @param endDateIso
 * @returns number
 */
export function getMinutesDifference(
  startDateIso: string,
  endDateIso: string,
): number {
  const date1 = new Date(startDateIso) as unknown as number;
  const date2 = new Date(endDateIso) as unknown as number;

  // Calculate the difference in milliseconds
  const differenceInMillis = Math.abs(date2 - date1);

  // Convert milliseconds to minutes
  const differenceInMinutes = differenceInMillis / (1000 * 60);

  return differenceInMinutes;
}

/**
 * Given an ISO date it will return the same date but without any minute passed by
 * @param dateISO
 * @returns string
 */
export function getDayStartIso(dateIso: string): string {
  const date = new Date(dateIso);

  // Set the time to midnight
  date.setHours(0, 0, 0, 0);

  // Format the result as a string in ISO format
  const resultDateString = date.toISOString();

  return resultDateString;
}

/**
 * Given an ISO date returns the week day name
 * @param isoDate
 * @return string
 */
export function getDayNameFromISODate(dateIso: string): DayOfWeek {
  const date = new Date(dateIso);
  const dayIndex = date.getUTCDay();

  return UTC_WEEKLY_DAYS[dayIndex];
}

/**
 * Given a iso date returns the start and end date for same week as date given
 * @params dateIso
 * @returns an object containing startDate and endDate
 */
export function getWeekStartEndDates(dateIso: string): IWeekStartEnd {
  const date = new Date(dateIso);

  const dayOfWeek = date.getDay();

  // Calculate the difference between the current day and the start of the week (Sunday) in UTC
  const daysUntilStartOfWeek = dayOfWeek === 0 ? 0 : dayOfWeek - 1;

  // Calculate the start date of the week by subtracting daysUntilStartOfWeek
  const startDate = new Date(date);
  // Adding 1 day for starting from monday
  startDate.setDate(date.getDate() - daysUntilStartOfWeek);
  startDate.setHours(0, 0, 0, 0);

  // Calculate the end date of the week by adding remaining days until the end of the week (Saturday)
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6);
  endDate.setHours(23, 59, 59, 999);

  // Converting it to RO format
  return { startDate: startDate, endDate: endDate };
}

/**
 * Formats an iso date to DD-MM-YYYY format
 * @param dateIso
 * @returns string
 */
export function formatDate(dateIso: string): string {
  return new Intl.DateTimeFormat('ro', { dateStyle: 'medium' }).format(
    new Date(dateIso),
  );
}

/**
 * Gets all the dates in a week given a day as an input
 * @param dateIso
 * @returns an array of strings containing iso dates of the week
 */
export function getWeekDates(dateIso?: string): string[] {
  const weekDates = [];
  const currentDate = dateIso ? new Date(dateIso) : new Date();

  // Set the day to Monday
  currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 1);

  for (let i = 1; i < 8; i++) {
    weekDates.push(new Date(currentDate).toISOString()); // Clone the date to avoid modifying the original
    currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
  }

  return weekDates;
}

/**
 * Gets an array of strings that includes each day of the month, rapresented in ISO
 * @param dateIso
 * @returns string array
 */
export function getMonthDates(dateIso: string): string[] {
  const currentDate = new Date(dateIso);

  currentDate.setDate(1);

  while (currentDate.getDay() !== 1) {
    currentDate.setDate(currentDate.getDate() - 1);
  }

  // Create an array to store the result
  const resultArray = [];

  // Generate the array with 35 days
  for (let i = 0; i < 35; i++) {
    resultArray.push(new Date(currentDate).toISOString());
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return resultArray;
}

/**
 * Gets an iso string representing the date parsed with desired time changed
 * @param dateIso
 * @param newTime
 * @returns new date iso string
 */
export function changeDateTime(dateIso: string, newTime: string) {
  const parsedDate = new Date(dateIso);
  const [newHours, newMinutes] = newTime.split(':').map(Number);

  if (newHours !== undefined) parsedDate.setUTCHours(newHours);
  if (newMinutes !== undefined) parsedDate.setUTCMinutes(newMinutes);

  return parsedDate.toISOString();
}

/**
 * Given a valid date of tipe "YYYY-MM-DD" and valid hours "hh:mm", returns an iso string representing the two variables merged
 * @param date
 * @param time
 * @returns full date iso string
 */
export function mergeDateAndTime(date: string, time: string): string {
  if (!isValidDate(date) || !isValidTime(time)) {
    throw new Error('Invalid date or time format.');
  }

  const dateObj = new Date(date);
  const timeObj = new Date(`1970-01-01T${time}:00`);

  const mergedDateObj = new Date(dateObj.getTime() + timeObj.getTime());

  return mergedDateObj.toISOString();
}

/**
 * Given a date validates the format of it
 * @param date
 * @returns boolean
 */
export function isValidDate(date: string): boolean {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  return regex.test(date);
}

/**
 * Given a time validates the format of it
 * @param time
 * @returns boolean
 */
export function isValidTime(time: string): boolean {
  const regex = /^\d{2}:\d{2}$/;
  return regex.test(time);
}

/**
 * Given an iso date returns the iso representing a hour later
 * @param presetDate
 * @returns iso date
 */
export function getOneHourLaterDate(presetDate?: string): string {
  const date = presetDate ? new Date(presetDate) : new Date();
  date.setHours(date.getHours() + 1);

  return date.toISOString();
}

/**
 * Given an iso date returns a clean version of it in the format YYYY-MM-DDThh:mm
 * @param isoDate
 * @returns clean isoDate
 */
export const cleanIsoDate = (date: string) =>
  date.split(':').slice(0, 2).join(':');

/**
* Given a timestamp, this function returns the time elapsed since that timestamp in a human-readable format.
* If the timestamp is from the same day, it returns the time in 'HH:mm AM/PM' format.
* If the timestamp is from the previous day, it returns 'Yesterday HH:mm AM/PM'.
* Otherwise, it returns the date in 'day month' format.
* @param timestamp - A string representing a timestamp in ISO 8601 format.
* @returns A string representing the time elapsed since the timestamp.
* @throws Will throw an error if the timestamp is not a valid date string.
*/
export const getTimeElapsed = (timestamp: string, lang: string, yesterday: string) => {
  const now = new Date();
  const past = new Date(timestamp);

  if (isNaN(past.getTime())) {
    throw new Error(`Invalid timestamp: ${timestamp}`);
  }

  const isSameDay = now.toDateString() === past.toDateString();
  const isYesterday =
    now.getDate() - past.getDate() === 1 &&
    now.getMonth() === past.getMonth() &&
    now.getFullYear() === past.getFullYear();

  const timeFormat = new Intl.DateTimeFormat(lang, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }).format(past);

  if (isSameDay) {
    return timeFormat;
  } else if (isYesterday) {
    return `${yesterday} ${timeFormat}`;
  } else {
    return new Intl.DateTimeFormat(lang, {
      day: 'numeric',
      month: 'long',
    }).format(past);
  }
};