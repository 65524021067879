import { Box, IconButton } from '@mui/material';
import { FC } from 'react';
import { IconWrapper, Pulse } from './styled';

interface NotificationIconProps {
  handleClickOpen: (event: React.MouseEvent<HTMLButtonElement>) => void;
  showPulse: boolean;
}

const NotificationIcon: FC<NotificationIconProps> = ({
  handleClickOpen,
  showPulse,
}) => {
  const icon: string =
    'https://preclinic.dreamstechnologies.com/html/template/assets/img/icons/note-icon-01.svg';

  return (
    <IconButton onClick={handleClickOpen}>
      <IconWrapper>
        <Box component="img" src={icon} alt="Notifications" />
        {showPulse && <Pulse />}
      </IconWrapper>
    </IconButton>
  );
};

export default NotificationIcon;
