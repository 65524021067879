import { Loadable } from '@/components';
import { AuthState, useAuthContext } from '@/providers';
import i18n from '@/translate/i18n';
import { selectors, useAppSelector } from '@/utils';
import { FC, lazy, useEffect } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { AssistantRoute } from './assistant-route/component';
import { AuthRoute, RedirectToAuth } from './auth-route/component';
import { CommonRoute } from './common-route/component';
import { DoctorRoute } from './doctor-route/component';

// AUTH ROUTES
const SignIn = Loadable(lazy(() => import('@/views/auth/sign-in')));
const ActivatePage = Loadable(lazy(() => import('@/views/auth/activate')));
const ResetPassword = Loadable(
  lazy(() => import('@/views/auth/reset-password')),
);
const ForgotPassword = Loadable(
  lazy(() => import('@/views/auth/forgot-password')),
);
const OtpInfo = Loadable(lazy(() => import('@/views/auth/otp-info')));

// DOCTOR ROUTES
const DoctorHome = Loadable(lazy(() => import('@/views/doctor/home')));
const QuickPage = Loadable(lazy(() => import('@/views/doctor/quick-actions')));
const ConfigHome = Loadable(lazy(() => import('@/views/doctor/config')));
const Team = Loadable(lazy(() => import('@/views/doctor/team')));
const AddNurse = Loadable(lazy(() => import('@/views/doctor/team/add-team')));
const Payment = Loadable(lazy(() => import('@/views/doctor/payment')));

// ASSISTANT ROUTES
const AssistantHome = Loadable(lazy(() => import('@/views/assistant/home')));

// COMMON ROUTES
const Patients = Loadable(lazy(() => import('@/views/patients')));
const PatientProfile = Loadable(
  lazy(() => import('@/views/patients/patient-profile')),
);
const AddPatient = Loadable(lazy(() => import('@/views/patients/add-patient')));
const Calendar = Loadable(lazy(() => import('@/views/common/calendar')));
const Settings = Loadable(lazy(() => import('@/views/common/settings')));
const AddProduct = Loadable(
  lazy(() => import('@/views/common/products-stock/add-product')),
);
const ProductsStock = Loadable(
  lazy(() => import('@/views/common/products-stock/home')),
);
const SingleProduct = Loadable(
  lazy(() => import('@/views/common/products-stock/single-product')),
);

// GUEST ROUTES
const Contact = Loadable(lazy(() => import('@/views/common/contact')));

export const RoutesContainer: FC = () => {
  const { language } = useAppSelector(selectors.utils);
  const { refreshAuthState } = useAuthContext();

  useEffect(() => {
    i18n.changeLanguage(language);
    // eslint-disable-next-line
  }, [language]);

  useEffect(() => {
    const handleUnauthenticated = () => {
      refreshAuthState(AuthState.SignedOut);
    };
    window.addEventListener('unauthenticated', handleUnauthenticated);

    return () => {
      window.removeEventListener('unauthenticated', handleUnauthenticated);
    };
  }, [refreshAuthState]);

  return (
    <>
      <HashRouter>
        <Routes>
          <Route
            path="/"
            element={<AuthRoute redirectPath="/doctor/dashboard" />}
          >
            <Route path="/" element={<RedirectToAuth />} />
            <Route path="/auth/sign-in" element={<SignIn />} />
            <Route path="/auth/activate" element={<ActivatePage />} />
            <Route path="/auth/otp-info" element={<OtpInfo />} />
            <Route path="/auth/reset-password" element={<ResetPassword />} />
            <Route path="/auth/forgot-password" element={<ForgotPassword />} />
          </Route>
          <Route
            path="/doctor"
            element={<DoctorRoute redirectPath="/assistant" />}
          >
            <Route path="/doctor/dashboard" element={<DoctorHome />} />
            <Route path="/doctor/quick" element={<QuickPage />} />
            <Route path="/doctor/requests" element={<DoctorHome />} />
            <Route path="/doctor/config" element={<ConfigHome />} />
            <Route path="/doctor/team" element={<Team />} />
            <Route path="/doctor/payment" element={<Payment />} />
            <Route path="/doctor/team/add-team" element={<AddNurse />} />
          </Route>
          <Route
            path="/assistant"
            element={<AssistantRoute redirectPath="/" />}
          >
            <Route path="/assistant" element={<AssistantHome />} />
          </Route>
          <Route path="/common" element={<CommonRoute redirectPath="/" />}>
            <Route path="/common/all-patients" element={<Patients />} />
            <Route
              path="/common/patient-profile/:patientId"
              element={<PatientProfile />}
            />
            <Route path="/common/add-patient" element={<AddPatient />} />
            <Route path="/common/calendar" element={<Calendar />} />
            <Route path="/common/settings" element={<Settings />} />
            <Route path="/common/products" element={<ProductsStock />} />
            <Route path="/common/add-product" element={<AddProduct />} />
            <Route
              path="/common/single-product/:id"
              element={<SingleProduct />}
            />
          </Route>
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </HashRouter>
    </>
  );
};
