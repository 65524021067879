import { Text } from '@/components';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  dateHandlersPerViewMode,
  getFormattedDatePerViewMode,
} from './helpers';
import { IDateStepperProps } from './types';

export const DateStepper: FC<IDateStepperProps> = ({
  currentViewMode,
  onSelectedDateChange,
  selectedDate,
}) => {
  const { t } = useTranslation<string>();
  return (
    <>
      <Text variant="h5">
        {getFormattedDatePerViewMode[currentViewMode](selectedDate, t('lang'))}
      </Text>
      <IconButton
        onClick={() =>
          dateHandlersPerViewMode[currentViewMode].previous(
            onSelectedDateChange,
            selectedDate,
          )
        }
      >
        <ArrowBackIos />
      </IconButton>
      <IconButton
        onClick={() =>
          dateHandlersPerViewMode[currentViewMode].next(
            onSelectedDateChange,
            selectedDate,
          )
        }
      >
        <ArrowForwardIos />
      </IconButton>
    </>
  );
};
