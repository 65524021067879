import { StyledButton, StyledInput } from '@/components';
import { MOCK_PATIENT } from '@/mock';
import { APPOINTMENT_CATEGORIES } from '@/utils/constants';
import { Grid, MenuItem } from '@mui/material';
import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IFormComponentProps } from './types';

export const AppointmentForm: FC<IFormComponentProps> = ({
  defaultValues,
  onSubmit,
}) => {
  const { t } = useTranslation<string>();
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<IFormComponentProps['defaultValues']>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const patientWatch = watch('patient');
  const categoryWatch = watch('category');

  const onSubmitForm: SubmitHandler<IFormComponentProps['defaultValues']> = (
    data,
  ) => {
    onSubmit?.(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Grid container spacing={1}>
        <Grid item sm={12}>
          <StyledInput
            value={patientWatch}
            onChange={(e) => setValue('patient', e.target.value)}
            select
            label={t(
              'pages.common.calendar.scheduler.schedule-pop-up.fields.patient',
            )}
          >
            {MOCK_PATIENT.map((patient) => (
              <MenuItem
                key={`add-appointment-form-patient-${patient._id}`}
                value={patient._id}
              >
                {patient.firstName} {patient.lastName}
              </MenuItem>
            ))}
          </StyledInput>
        </Grid>
        <Grid item sm={12}>
          <StyledInput
            value={categoryWatch}
            onChange={(e) => setValue('category', e.target.value)}
            select
            label={t(
              'pages.common.calendar.scheduler.schedule-pop-up.fields.category',
            )}
          >
            {APPOINTMENT_CATEGORIES.map((cat) => (
              <MenuItem key={`add-appointment-form-cat-${cat}`} value={cat}>
                {cat}
              </MenuItem>
            ))}
          </StyledInput>
        </Grid>
        <Grid item sm={12}>
          <StyledInput
            {...register('description', {
              required: t('common.required-field'),
            })}
            label={t(
              'pages.common.calendar.scheduler.schedule-pop-up.fields.description',
            )}
            error={!!errors.description}
            helperText={errors.description?.message}
            labelColor="secondary"
          />
        </Grid>
        <Grid item sm={12}>
          <StyledInput
            {...register('date', { required: t('common.required-field') })}
            label={t(
              'pages.common.calendar.scheduler.schedule-pop-up.fields.date',
            )}
            error={!!errors.date}
            helperText={errors.date?.message}
            labelColor="secondary"
            type="date"
          />
        </Grid>
        <Grid item sm={12}>
          <StyledInput
            {...register('startHours', {
              required: t('common.required-field'),
            })}
            label={t(
              'pages.common.calendar.scheduler.schedule-pop-up.fields.start-hour',
            )}
            error={!!errors.startHours}
            helperText={errors.startHours?.message}
            labelColor="secondary"
            type="time"
          />
        </Grid>
        <Grid item sm={12}>
          <StyledInput
            {...register('endHours', { required: t('common.required-field') })}
            label={t(
              'pages.common.calendar.scheduler.schedule-pop-up.fields.end-hour',
            )}
            error={!!errors.endHours}
            helperText={errors.endHours?.message}
            labelColor="secondary"
            type="time"
          />
        </Grid>
        <Grid container item justifyContent="center" sm={12}>
          <StyledButton disabled={!isValid} type="submit">
            {t('common.save')}
          </StyledButton>
        </Grid>
      </Grid>
    </form>
  );
};
