import { ArrowBack } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FC } from 'react';
import { Wrapper } from './styled';

interface IProps {
  open: boolean;
  closeDrawer: () => void;
}

const DrawerHeader: FC<IProps> = ({ open, closeDrawer }) => (
  <Wrapper>
    <IconButton
      onClick={closeDrawer}
      aria-label="toggle"
      aria-labelledby="toggle"
    >
      <ArrowBack
        fontSize="large"
        sx={{
          transform: `rotate(${open ? 0 : 180}deg)`,
          transition: 'all 0.3s ease-in',
        }}
      />
    </IconButton>
  </Wrapper>
);

export { DrawerHeader };
