import { cleanIsoDate, getOneHourLaterDate } from '@/utils';
import { APPOINTMENT_CATEGORIES } from '@/utils/constants';

export const appointmentFormDefaultValues = (presetDate?: string) => ({
  description: '',
  patient: '',
  category: APPOINTMENT_CATEGORIES[0],
  date: (presetDate || new Date().toISOString()).split('T')[0],
  startHours: cleanIsoDate(presetDate || new Date().toISOString()).split(
    'T',
  )[1],
  endHours: cleanIsoDate(getOneHourLaterDate(presetDate)).split('T')[1],
});

export const vacationFormDefaultValues = (presetDate?: string) => ({
  allDay: false,
  startDate: cleanIsoDate(presetDate || new Date().toISOString()),
  endDate: cleanIsoDate(getOneHourLaterDate(presetDate)),
});
