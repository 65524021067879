import React, { ElementType, forwardRef } from 'react';
import {
  Box,
  StandardTextFieldProps,
  BoxProps,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { CustomInputBase, LabelColor, StyledInputLabel } from './styled';

export interface IStyledInputProps extends StandardTextFieldProps {
  wrapperProps?: BoxProps;
  labelColor?: LabelColor;
  iconAction?: () => void;
  icon?: React.ReactNode;
  as?: ElementType;
  isDisabled?: boolean;
}

const StyledInput = forwardRef<any, IStyledInputProps>((props, ref) => {
  const {
    InputLabelProps,
    wrapperProps,
    labelColor = 'primary',
    label,
    placeholder,
    icon,
    iconAction,
    InputProps,
    isDisabled,
    ...args
  } = props;

  return (
    <Box width="100%" {...wrapperProps}>
      <StyledInputLabel labelColor={labelColor} {...InputLabelProps}>
        {label}
      </StyledInputLabel>
      <CustomInputBase
        ref={ref}
        placeholder={placeholder || 'Enter value'}
        {...args}
        disabled={isDisabled}
        InputProps={{
          ...InputProps,
          endAdornment: icon && (
            <InputAdornment position="end">
              {iconAction ? (
                <IconButton
                  onClick={iconAction}
                  aria-label="input-action"
                  aria-labelledby="input-action"
                >
                  {icon}
                </IconButton>
              ) : (
                <>{icon}</>
              )}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
});

StyledInput.defaultProps = {
  wrapperProps: undefined,
};

export { StyledInput };
