import { createContext, useContext } from 'react';
import { AuthState, IAuthContext } from './types';

export const AuthContext = createContext<IAuthContext>({
  authState: AuthState.SignedOut,
  user: undefined,
  signIn: async () => {},
  signOut: async () => {},
  activate: async () => {},
  validateOtp: async () => {},
  refreshAuthState: () => {},
});

export const useAuthContext = (): IAuthContext => {
  return useContext(AuthContext);
};
