import { VIEW_MODE_ARRAY } from '@/components/scheduler/constants';
import { ViewMode } from '@/components/scheduler/types';
import { Box, MenuItem, Select } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IViewModeSelectProps } from './types';

export const ViewModeSelect: FC<IViewModeSelectProps> = ({
  currentViewMode,
  onViewModeChange,
}) => {
  const { t } = useTranslation<string>();
  return (
    <Box>
      <Select
        value={currentViewMode}
        onChange={(e) => onViewModeChange(e.target.value as ViewMode)}
      >
        {VIEW_MODE_ARRAY.map((mode) => (
          <MenuItem
            value={mode}
            key={`scheduler-header-view-mode-select-${mode}`}
          >
            {t(`common.${mode}`)}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
