import env_config from '@/env.config';
import { decryptData, encryptData } from '@/utils';
import { createTransform } from 'redux-persist';

export function transform<ST extends {}, S = any, RS = any>() {
  const secretKey = env_config.encryption_persist;

  if (!secretKey)
    throw new Error('store > transform: encryption secret key is missing.');

  return createTransform<ST, string, S, RS>(
    (inboundState, _key) => encryptData(inboundState, secretKey),
    (outboundState, _key) => {
      if (typeof outboundState !== 'string') {
        console.warn('store > transform: no persisted state was found.');
      }

      try {
        const parsedState = decryptData<ST>(outboundState, secretKey);
        return parsedState;
      } catch (e) {
        console.error('store > transform: ', e);
      }
      return {} as ST;
    },
  );
}
