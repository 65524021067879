import { createContext, useContext } from 'react';
import { IProductsContext } from './types';

export const ProductsContext = createContext<IProductsContext>({
  products: [],
  setProducts: () => {},
  addProduct: () => {},
  updateProduct: () => {},
  deleteProduct: () => {},
  getProductById: () => undefined,
});

export const useProductsContext = (): IProductsContext => {
  return useContext(ProductsContext);
};
