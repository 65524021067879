import { Text } from '@/components/text';
import { useAuthContext } from '@/providers';
import { capitalize } from '@/utils';
import { Theme, useTheme } from '@mui/material';
import { UserInformationWrapper } from './styled';

const UserInformation = () => {
  const { user } = useAuthContext();
  const theme = useTheme<Theme>();

  return (
    <UserInformationWrapper>
      <Text variant="body1" isBold sx={{ color: theme.palette.primary.main }}>
        {`${user?.firstName} ${user?.lastName}`}
      </Text>

      <Text variant="body2" colorVariant="disabled">
        {capitalize(user?.role as string)}
      </Text>
    </UserInformationWrapper>
  );
};

export default UserInformation;
