import { MainLayout, SideDrawer } from '@/components';
import { useAuthContext } from '@/providers';
import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { IRouteProps } from '../types';

export const AssistantRoute: FC<IRouteProps> = ({ redirectPath }) => {
  const { user } = useAuthContext();

  if (user?.role === 'assistant' && user?.connection === 'online')
    return (
      <MainLayout>
        <SideDrawer />
        <Outlet />
      </MainLayout>
    );

  return <Navigate to={redirectPath} replace />;
};
