import { selectors, useAppSelector } from '@/utils';
import { FC, ReactNode } from 'react';
import { Header } from '../header';
import { MainWrapper } from './styled';

const MainLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const { isDrawerOpen } = useAppSelector(selectors.utils);

  return (
    <MainWrapper isDrawerOpen={isDrawerOpen}>
      <Header />
      {children}
    </MainWrapper>
  );
};

export { MainLayout };
