import { AppointmentCategoryType, IAppointment, IVacation } from '@/types';
import { useState, useEffect } from 'react';

export const useFilteredAppointments = (
  appointments: (IAppointment | IVacation)[],
  filterDate: string,
  filterCategory: AppointmentCategoryType | null,
): (IAppointment | IVacation)[] => {
  const [filteredAppointments, setFilteredAppointments] = useState<
    (IAppointment | IVacation)[]
  >([]);

  useEffect(() => {
    const filtered = appointments.filter(
      (appointment) => appointment.date === filterDate,
    );

    if (filterCategory) {
      const temp = filtered.filter(
        (appointment) =>
          appointment.type === 'appointment' &&
          appointment.category === filterCategory,
      );
      setFilteredAppointments(temp);
    } else {
      setFilteredAppointments(filtered);
    }
  }, [appointments, filterDate, filterCategory]);

  return filteredAppointments;
};
