import { Sides } from './types';

export const isDateInCurrentMonth = (date: string, selectedDate: string) =>
  new Date(selectedDate).getUTCMonth() === new Date(date).getUTCMonth();

export function getBordersSidesArray(index: number): Sides[] {
  if (index === 0) return ['right', 'left', 'bottom'];
  if (index > 0 && index < 7) return ['right', 'bottom'];
  if (index === 7 || index === 14 || index === 21 || index === 28)
    return ['bottom', 'left', 'right'];
  return ['bottom', 'right'];
}
