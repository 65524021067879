import { Box, styled } from '@mui/material';

export const SiderBox = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export const AppointmentBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  boxShadow: theme.shadows[1],
  borderRadius: theme.spacing(1),
  width: '100%',
  flexWrap: 'wrap',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: theme.palette.grey[400],
  },
}));
