import { Button, Table, TableContainer } from '@mui/material';
import { ChangeEvent, FC, useEffect, useId, useMemo, useState } from 'react';
import { StyledInput } from '../input';
import { AlphaBoxWrap, SearchBoxWrap, TableWrapper } from './styled';
import { MemoizedTableBody } from './table-body/component';
import { MemoizedTableHeader } from './table-header/component';
import { CustomTablePagination } from './table-pagination/component';
import { ICustomTableProps } from './types';

// Define a basic structure for your row data
interface TableRow {
  [key: string]: any;
}

export const CustomTable: FC<ICustomTableProps> = ({
  data,
  loading = false,
  pagination = false,
  searchTable = false,
  customPagination,
  customFiltersSistem,
  alphaColSort = 'name',
  customAction
}) => {
  const tableId = useId();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const toggleSortDirection = (sortType: 'asc' | 'desc'): void => {
    setSortDirection(sortType);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchText(event.target.value);
    setPage(0); // Reset to the first page for new search results
  };

  const memoizedRows = useMemo(() => {
    let filteredRows: TableRow[] = data.rows || [];

    if (searchText) {
      filteredRows = filteredRows.filter((row: TableRow) =>
        Object.values(row).some(value =>
          (value?.toString() ?? '').toLowerCase().includes(searchText.toLowerCase())
        )
      );
    }

    if (sortDirection) {
      filteredRows.sort((a, b) => {
        const aValue = a[alphaColSort] ? a[alphaColSort].toString() : "";
        const bValue = b[alphaColSort] ? b[alphaColSort].toString() : "";
    
        if (sortDirection === 'asc') {
          // For ascending order
          return aValue.localeCompare(bValue);
        } else {
          // For descending order
          return bValue.localeCompare(aValue);
        }
      });
    }
    
    const slicedDataRows = filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    const emptyRowsCount = rowsPerPage - slicedDataRows.length;
    const emptyRows = Array.from({ length: emptyRowsCount }, () => ({}));

    return filteredRows.length > 0 ? slicedDataRows.concat(emptyRows) : slicedDataRows;
  }, [data.rows, rowsPerPage, page, searchText, sortDirection, alphaColSort]);

  useEffect(() => {
    if (loading) setPage(0);
  }, [loading]);

  return (
    <TableWrapper>
      {searchTable && (
        <SearchBoxWrap>
          <StyledInput
            sx={{ maxWidth: '200px'}}
            placeholder="Search"
            value={searchText}
            onChange={handleSearchChange}
            wrapperProps={{ maxWidth: '300px' }}
          />

          {customFiltersSistem}

          <AlphaBoxWrap>
            <Button variant={sortDirection === 'asc' ? 'outlined' : 'contained'} 
              onClick={() => toggleSortDirection('asc')} size='small' sx={{ height: '36px'}}>
              ABC
            </Button>
            <Button variant={sortDirection === 'asc' ? 'contained' : 'outlined'} 
              onClick={() => toggleSortDirection('desc')} size='small' sx={{ height: '36px'}}>
              ZYX
            </Button>
  
            {customAction}

          </AlphaBoxWrap>
        </SearchBoxWrap>
      )}
      <TableContainer>
        <Table>
          <MemoizedTableHeader
            tableId={tableId}
            columns={data.columns}
            searchEnabled={searchTable}
          />
          <MemoizedTableBody
            tableId={tableId}
            rows={memoizedRows}
            columns={data.columns}
            loading={loading}
          />
        </Table>
        {pagination && (
          <CustomTablePagination
            totalRows={data.rows?.length || 0}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            handleChangePage={(_, newPage) => setPage(newPage)}
            customComponent={customPagination}
          />
        )}
      </TableContainer>
    </TableWrapper>
  );
};