export const capitalize = (str: string): string =>
  `${str.at(0)?.toUpperCase()}${str.slice(1)}`;

export const minimize = (str: string): string =>
  `${str.at(0)?.toLowerCase()}${str.slice(1)}`;

export const { isArray } = Array;

export function ifIsArray<T>(el: any): T[] {
  return (isArray(el) ? [...el] : []) as T[];
}

export const normalizeCamelCase = (str: string): string =>
  str.replace(
    /[A-Z]+(?![a-z])|[A-Z]/g,
    ($: string, ofs: unknown[]) => (ofs ? ' ' : '') + $.toLowerCase(),
  );

/**
 * This function can be used to generate random mock date
 * forom now in 2 weeks ahead for apointments and similar uses
 *
 * Example usage
 * const updatedDate = generateMockDate();
 * @returns a string of format YYYY-MM-DD
 */

export const generateMockDate = (): string => {
  // Get the current date
  const currentDate = new Date();

  // Generate a random number of days between 1 and 14
  const daysAhead = Math.floor(Math.random() * 14) + 1;

  // Add this number of days to the current date
  currentDate.setDate(currentDate.getDate() + daysAhead);

  // Format the new date into the "YYYY-MM-DD" string format and return it
  const year = currentDate.getFullYear();
  let month = (currentDate.getMonth() + 1).toString();
  let day = currentDate.getDate().toString();

  // Padding month and day with '0' if they are less than 10
  month = month.length < 2 ? `0${month}` : month;
  day = day.length < 2 ? `0${day}` : day;

  return `${year}-${month}-${day}`;
};

/**
 * Groups the elements of a given iterable according to the string values returned by a provided callback function (keyFn).
 * The returned object has separate properties for each group, containing arrays with the elements in the group.
 * @param items array
 * @param keyFn
 * @returns an object
 */
export function groupBy<T, K extends string | symbol | number>(
  items: T[],
  keyFn: (item: T) => K,
): Record<K, T[]> {
  const groups = {} as Record<K, T[]>;
  for (const item of items) {
    const key = keyFn(item);
    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(item);
  }
  return groups;
}
