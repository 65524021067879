import { getDailyHoursArray } from './time';

export const DAILY_HOURS = getDailyHoursArray();
export const WEEKLY_DAYS = [
  'Luni',
  'Marti',
  'Miercuri',
  'Joi',
  'Vineri',
  'Sambata',
  'Duminica',
] as const;
export const UTC_WEEKLY_DAYS = [
  'Duminica',
  'Luni',
  'Marti',
  'Miercuri',
  'Joi',
  'Vineri',
  'Sambata',
] as const;
export const MONTHS = [
  'Ianuarie',
  'Februarie',
  'Martie',
  'Aprilie',
  'Mai',
  'Iunie',
  'Iulie',
  'August',
  'Septembrie',
  'Optombrie',
  'Noiembrie',
  'Decembrie',
] as const;
