import { FC } from 'react';
import {
  CheckCircleOutline,
  Delete,
  ErrorOutline,
  InsertDriveFile,
} from '@mui/icons-material';
import {
  CircularProgress,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import { IUploadFile } from '../component';
import { StatusBox } from './styled';
import { Text } from '@/components/text';
import { PreviewContainer, PreviewImg, PreviewList } from './styled';

interface IUploadedListProps {
  selectedFiles: IUploadFile[];
  deleteFile: (fileName: string) => void;
}

const renderFileIcon = (file: IUploadFile) => {
  switch (file.status) {
    case 'loading':
      return <CircularProgress size={20} />;
    case 'success':
      return (
        <StatusBox>
          <CheckCircleOutline color="success" />
          <Text spacing={0}>Uploaded</Text>
        </StatusBox>
      );
    case 'error':
      return <ErrorOutline color="error" />;
    default:
      return null;
  }
};

export const UploadedList: FC<IUploadedListProps> = ({
  selectedFiles,
  deleteFile,
}) => {
  return (
    <PreviewList>
      {selectedFiles.map((file, index) => (
        <ListItem key={`${file.file.name}-${index}`}>
          <ListItemIcon>
            <InsertDriveFile />
          </ListItemIcon>
          <PreviewContainer
            title={<PreviewImg src={file.preview} alt={file.file.name} />}
            placement="bottom-start"
            disableHoverListener={!file.preview}
          >
            <ListItemText primary={file.file.name} />
          </PreviewContainer>
          <ListItemSecondaryAction>
            {renderFileIcon(file)}
            <IconButton edge="end" onClick={() => deleteFile(file.file.name)}>
              <Delete />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </PreviewList>
  );
};
