import { useAppointmentsContext } from '@/providers/appointments';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/ro'; // Import Romanian locale
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarContainer } from './styled';

type DayMappingType = {
  [key in 'Lu' | 'Ma' | 'Mi' | 'Jo' | 'Vi' | 'Sâ' | 'Du']: string;
};

const dayMapping: DayMappingType = {
  Lu: 'Lun',
  Ma: 'Mar',
  Mi: 'Mie',
  Jo: 'Joi',
  Vi: 'Vin',
  Sâ: 'Sâm',
  Du: 'Dum',
};
dayjs.locale('ro');
export const MiniCalendar: FC = () => {
  const { t } = useTranslation<string>();
  const { setFilterDate, filterDate } = useAppointmentsContext();
  const currentDate = dayjs(filterDate);

  const handleNewDate = (newVal: any) => {
    const setDate = newVal.format('YYYY-MM-DD');
    setFilterDate(setDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={t('lang')}>
      <CalendarContainer>
        <DateCalendar
          value={currentDate}
          onChange={(newVal) => handleNewDate(newVal)}
          showDaysOutsideCurrentMonth
          dayOfWeekFormatter={(dayAbbreviation) => {
            return (
              dayMapping[dayAbbreviation as keyof DayMappingType] ||
              dayAbbreviation
            );
          }}
        />
      </CalendarContainer>
    </LocalizationProvider>
  );
};
