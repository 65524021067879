import { TableHead, TableRow } from '@mui/material';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledTableCell } from '../styled';
import { ICustomTableHeaderProps } from './types';

const CustomTableHeader: FC<ICustomTableHeaderProps> = ({
  tableId,
  columns,
  searchEnabled,
}) => {
  const { t } = useTranslation<string>();
  return (
    <TableHead>
      <TableRow>
        {columns.map((col, i) => (
          <StyledTableCell
            sx={{
              width: col.width,
              borderRadius: `${i === 0 ? '10px 0px 0px 0px' : '0px'} ${
                i === columns.length - 1 ? '0px 10px 0px 0px' : '0px'
              }`,
              backgroundColor: searchEnabled ? '#fff!important' : 'inherit'
            }}
            align={col.align}
            key={`table-header-cell-${tableId}-${i}`}
          >
            {t(`components.table.${col.key}`).toUpperCase()}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const MemoizedTableHeader = memo(CustomTableHeader);
