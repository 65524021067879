import { FC, useMemo } from 'react';
import { IMonthProps } from './types';
import { UTC_WEEKLY_DAYS, getMonthDates } from '@/utils';
import {
  MonthDayGridItem,
  MonthGridContainer,
  AppointmentsBox,
  AppointmentBox,
} from './styled';
import { Text } from '@/components';
import { isDateInCurrentMonth, getBordersSidesArray } from './helpers';
import {
  AppointmentPopover,
  useAppointmentPopover,
} from '../appointment-popover/component';
import { useSchedulerPropsContext } from '../../provider/context';

export const Month: FC<IMonthProps> = ({ selectedDate }) => {
  const days = useMemo(() => getMonthDates(selectedDate), [selectedDate]);
  const { appointments } = useSchedulerPropsContext();
  const { appointmentPopoverProps, openAppointmentPopover } =
    useAppointmentPopover();

  return (
    <>
      <MonthGridContainer container spacing={0}>
        {days.map((day, i) => (
          <MonthDayGridItem
            key={`scheduler-month-day-item-${day}`}
            container
            item
            xs={12 / 7}
            borders={getBordersSidesArray(i)}
            isInCurrentMonth={isDateInCurrentMonth(day, selectedDate)}
          >
            <AppointmentsBox>
              <Text>
                {UTC_WEEKLY_DAYS[new Date(day).getUTCDay()]}{' '}
                <strong>{new Date(day).getUTCDate()}</strong>
              </Text>
              {appointments?.[day.split('T')[0]]?.map((appointment) => (
                <AppointmentBox
                  onClick={(e) => openAppointmentPopover(e, appointment)}
                  key={`scheduler-month-day-${day}-appointment-${appointment.date}-${appointment.startHours}`}
                >
                  <Text
                    style={{ padding: 0 }}
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                  >
                    {appointment.type === 'appointment'
                      ? appointment.category
                      : 'Vacation'}
                  </Text>
                </AppointmentBox>
              ))}
            </AppointmentsBox>
          </MonthDayGridItem>
        ))}
      </MonthGridContainer>
      <AppointmentPopover {...appointmentPopoverProps} />
    </>
  );
};
