import { Box, BoxProps, styled } from '@mui/material';
import { FC } from 'react';

export const AvatarBase = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: 'center',
  backgroundColor: theme.palette.grey[500],
}));

export interface AvatarContainerProps extends BoxProps {
  isSmall?: boolean;
}

export const AvatarContainer: FC<AvatarContainerProps> = styled(
  Box as FC<AvatarContainerProps>,
  {
    shouldForwardProp: (prop) => !['isSmall'].includes(prop as string),
  },
)(({ isSmall, theme }) => ({
  width: isSmall ? '56px' : '64px',
  height: isSmall ? '56px' : '64px',
  borderRadius: '50%',
  overflow: 'hidden',
  border: `1px solid ${theme.palette.primary.dark}`,
}));
