import { Theme, Typography, styled } from '@mui/material';
import { FC } from 'react';
import { CustomTextProps } from './component';

// styled component
const StyledText = styled(Typography as FC<CustomTextProps>, {
  shouldForwardProp: (prop) =>
    !['fullWidth', 'spacing', 'colorVariant', 'isBold'].includes(
      prop as string,
    ),
})(
  ({
    theme,
    fullWidth,
    spacing,
    colorVariant = 'primary',
    isBold,
  }: CustomTextProps & { theme?: Theme }) => ({
    width: fullWidth,
    fontFamily: "'Poppins', sans-serif",
    padding: theme?.spacing(spacing as number),
    fontWeight: isBold ? 'bold' : 'inherit',
    color: theme?.palette.text[colorVariant],
  }),
);

export default StyledText;
