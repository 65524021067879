import { Wrapper } from '@/components';
import { FC } from 'react';
import { ITeethRow } from '../assets/teeth';
import { ToothSide } from './ToothSide';

interface IProps {
  teethRow: ITeethRow;
  handleSelectTooth: (
    id: string,
    category: 'notes' | 'files' | "Intervention" | undefined,
  ) => void;
  handleRemoved: (medicalId: string) => void;
}

export const TeethRow: FC<IProps> = ({
  handleSelectTooth,
  teethRow,
  handleRemoved,
}) => {
  return (
    <Wrapper bgVariant="paper" sx={{ gap: 0 }}>
      <Wrapper bgVariant="paper" justifyCenter width={'740px'} sx={{ gap: 0 }}>
        <ToothSide
          teethSide={teethRow.teethLeft}
          orientation={teethRow.rowOrientation}
          handleSelectTooth={handleSelectTooth}
          handleRemoved={handleRemoved}
        />
        <ToothSide
          teethSide={teethRow.teethRight}
          orientation={teethRow.rowOrientation}
          handleSelectTooth={handleSelectTooth}
          handleRemoved={handleRemoved}
        />
      </Wrapper>
    </Wrapper>
  );
};
