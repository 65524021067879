import { ErrorRounded } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import { FC, useCallback } from 'react';
import { Text } from '../text';
import { StyledButton } from '../button';

export const AppError: FC = () => {
  const cleanupAndRefresh = useCallback(() => {
    localStorage.clear();
    window.location.reload();
  }, []);

  return (
    <Box width="100%" height="100%" justifyContent="center" alignItems="center">
      <Grid container spacing={2}>
        <Grid container item xs={12} justifyContent="center">
          <ErrorRounded color="error" sx={{ fontSize: 50 }} />
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <Text variant="h4">Something went wrong!</Text>
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <Text variant="h6">
            Please try again later or try to refresh the page clicking the
            button below.
          </Text>
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <StyledButton onClick={cleanupAndRefresh}>Refresh</StyledButton>
        </Grid>
      </Grid>
    </Box>
  );
};
