/**
 * Type used to describe the current auth state
 */
export enum AuthState {
  Loading,
  SignedOut,
  SignedIn,
  NotPaid,
  NetworkError,
}

/**
 * Type used to describe the role of the user if logged
 */
export type UserRole = 'doctor' | 'assistant' | null | undefined;

/**
 * Type used to describe the user data cached into local storage
 */

export type UserConnection = 'offline' | 'online';

export interface IUser {
  role: UserRole;
  connection: UserConnection;
  email: string;
  onboarding_completed: boolean;
  firstName: string;
  lastName: string;
}

/**
 * Type used to describe the data needed for logging in
 */
export interface IUserCredentials {
  email: string;
  password: string;
}

export interface IUserSignIn extends IUserCredentials {
  clientId: string;
  applicationId: string;
}

export interface IValidateOtpData {
  sessionId: string;
  password: string;
  otp: string;
}

/**
 * Type used to describe the role of the user if logged
 */
export interface IAuthContext {
  authState: AuthState;
  user: IUser | undefined;
  signIn: (data: IUserSignIn) => Promise<void>;
  signOut: () => Promise<void>;
  activate: (
    data: IUserCredentials,
    onSuccess: (pass: string) => void,
  ) => Promise<void>;
  validateOtp: (
    data: IValidateOtpData,
    onSuccess?: () => void,
  ) => Promise<void>;
  refreshAuthState: (authState: AuthState) => void;
}
