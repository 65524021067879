import { useAppointments } from '@/hooks';
import { FC, ReactNode, useState } from 'react';
import { AppointmentsContext } from './context';
import { AppointmentCategoryType } from '@/types';
import dayjs from 'dayjs';
import { IActionDialogState } from './types';
import {
  AddAppointmentDialog,
  DeleteAppointmentDialog,
  EditAppointmentDialog,
} from '@/components';

const ActionDialogs = {
  add: AddAppointmentDialog,
  edit: EditAppointmentDialog,
  delete: DeleteAppointmentDialog,
};

export const AppointmentsProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const todayDate = dayjs().format('YYYY-MM-DD');
  const [filterDate, setFilterDate] = useState<string>(todayDate);
  const [filterCategory, setFilterCategory] =
    useState<AppointmentCategoryType | null>(null);
  const { appointments } = useAppointments({ filterCategory, filterDate });
  const [actionDialog, setActionDialog] = useState<IActionDialogState>();
  const CurrentActionDialog =
    actionDialog?.action && ActionDialogs[actionDialog?.action];

  const setToday = (): void => {
    setFilterDate(todayDate);
  };

  const actionAppointmentsDialog = (dialogState: IActionDialogState) =>
    setActionDialog(dialogState);

  return (
    <AppointmentsContext.Provider
      value={{
        appointments,
        setFilterDate,
        setFilterCategory,
        setToday,
        filterDate,
        filterCategory,
        actionAppointmentsDialog,
      }}
    >
      {CurrentActionDialog && (
        <CurrentActionDialog
          onDismiss={() => setActionDialog({})}
          // Add action dialog props
          presetDate={actionDialog.presetDate}
          withVacation={actionDialog.withVacation}
          onCreateAppointment={(appointment) => console.log(appointment)}
          onCreateVacation={(vacation) => console.log(vacation)}
          // Edit/Delete dialog props
          appointment={actionDialog.appointment}
          onUpdateAppointment={(appointment) => console.log(appointment)}
          onUpdateVacation={(vacation) => console.log(vacation)}
          onDelete={(appointmentOrVacation) =>
            console.log(appointmentOrVacation)
          }
        />
      )}
      {children}
    </AppointmentsContext.Provider>
  );
};
