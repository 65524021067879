import { mergeDateAndTime } from '@/utils';
import { AppointmentsDictionary, IAppointment } from '../../types';
import { IVacation } from '@/types';

function filterOnComingAppointments(
  appointments: (IAppointment | IVacation)[],
): (IAppointment | IVacation)[] {
  const current = new Date();
  return appointments.filter(
    (appointment) =>
      new Date(mergeDateAndTime(appointment.date, appointment.startHours)) >
      current,
  );
}

export function aggregateAndFilterAppointments(
  appointments: AppointmentsDictionary,
): (IAppointment | IVacation)[] {
  const appointmentsArrays = Object.values(appointments);
  let aggregatedAppointments: (IAppointment | IVacation)[] = [];
  for (const appointments of appointmentsArrays) {
    aggregatedAppointments = aggregatedAppointments.concat(...appointments);
  }
  return filterOnComingAppointments(aggregatedAppointments);
}

export function formatDateTime(dateIso: string) {
  return new Intl.DateTimeFormat('ro', {
    dateStyle: 'short',
    timeStyle: 'short',
  }).format(new Date(dateIso));
}
