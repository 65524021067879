import { FC } from 'react';
import Joyride from 'react-joyride';
import { steps } from './constants';
import { IOnboardingComponentProps } from '@/providers';

export const FirstUseTourDoctor: FC<IOnboardingComponentProps> = ({
  onComplete,
}) => {
  return (
    <Joyride
      continuous
      disableCloseOnEsc
      callback={({ index }) => index === steps.length - 1 && onComplete}
      steps={steps}
      run={true}
    />
  );
};
