import { Box, styled } from '@mui/material';
import { FC, memo } from 'react';
import { BgVarType, WrapperProps, extendedProps } from './types';

const color = (bgVariant: BgVarType): 'primary' | 'secondary' | 'disabled' => {
  switch (bgVariant) {
    case 'paper':
      return 'primary';
    case 'default':
      return 'disabled';
    default:
      return 'disabled';
  }
};

const BaseWrapper: FC<WrapperProps> = styled(Box as FC<WrapperProps>, {
  shouldForwardProp: (prop: string) => !extendedProps.includes(prop),
})(
  ({
    theme,
    column = false,
    justifyCenter = false,
    justifyEnd = false,
    alignStart = false,
    bgVariant = 'default',
    paddingWrap = 0,
  }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: column ? 'column' : 'row',
    justifyContent: justifyCenter
      ? 'center'
      : (justifyEnd && 'flex-end') || 'flex-start',
    alignItems: alignStart ? 'flex-start' : 'center',

    backgroundColor: theme.palette.background[bgVariant],
    padding: theme.spacing(paddingWrap),
    color: theme.palette.text[color(bgVariant)],
    gap: theme.spacing(2),
  }),
);

export const Wrapper = memo(BaseWrapper);
