import { Box, styled } from '@mui/material';
import { ACTION_HEADER_HEIGHT } from '../../constants';

export const SchedulerLoaderBox = styled(Box)({
  height: `calc(100% - ${ACTION_HEADER_HEIGHT}px)`,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
});
