import { styled } from '@mui/material';

export const CalendarContainer = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  '.MuiDateCalendar-root': {
    width: '100%',
    maxWidth: '380px',
    height: 'auto',
    maxHeight: 'fit-content',
    '.MuiPickersCalendarHeader-root': {
      padding: 0,
      marginTop: 0,
      '.MuiPickersCalendarHeader-labelContainer': {
        fontWeight: 700,
        fontSize: '20px',
      },
      '.MuiPickersArrowSwitcher-root': {
        '.MuiButtonBase-root': {
          '.MuiSvgIcon-root': {
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.primary.main,
            borderRadius: '50%',
          },
        },
      },
    },
    '.MuiPickersFadeTransitionGroup-root': {
      '.MuiDayCalendar-header': {
        width: '100%',
        maxWidth: '380px',
        justifyContent: 'space-between',
        '.MuiTypography-root': {
          color: theme.palette.primary.main,
          fontWeight: 700,
          fontSize: '14px',
          height: '20px',
        },
      },
      '.MuiDayCalendar-monthContainer': {
        '.MuiDayCalendar-weekContainer': {
          width: '100%',
          maxWidth: '380px',
          justifyContent: 'space-between',
          '.MuiButtonBase-root': {
            color: theme.palette.text.primary,
            fontSize: '14px',
          },
          '.MuiPickersDay-dayOutsideMonth': {
            color: theme.palette.grey[600],
          },
          '.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.secondary,
          },
          '.MuiPickersDay-today': {
            border: `2px solid ${theme.palette.primary.main}`,
          },
        },
      },
    },
  },
}));
