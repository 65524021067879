import { createContext, useContext } from 'react';
import { IPatientsContext } from './types';

export const PatientsContext = createContext<IPatientsContext>({
  patients: [],
  setPatients: () => {},
  addPatient: () => {},
  updatePatient: () => {},
  deletePatient: () => {},
});

export const usePatientsContext = (): IPatientsContext => {
  return useContext(PatientsContext);
};
