import { ComponentType, FC, Suspense } from 'react';
import { Loader } from '../loader';

export function Loadable<P extends object>(Component: ComponentType<P>): FC<P> {
  return (props: P) => (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );
}
