import { Box, BoxProps } from '@mui/material';
import { ReactNode } from 'react';
import StyledPageWrapper from './styled';

export interface CustomPageWrapperProps extends BoxProps {
  maxWidth?: string;
  column?: boolean;
  children: ReactNode;
}

export const PageWrapper = ({
  maxWidth,
  children,
  column,
  ...boxProps
}: CustomPageWrapperProps) => {
  return (
    <Box width={'100%'} display={'flex'} justifyContent={'center'}>
      <StyledPageWrapper maxWidth={maxWidth} column={column} {...boxProps}>
        {children}
      </StyledPageWrapper>
    </Box>
  );
};

PageWrapper.defaultProps = {
  maxWidth: '1550px',
  column: false,
};
