import { Box, styled } from '@mui/material';
import { ACTION_HEADER_HEIGHT } from '../../constants';

export const ActionHeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  height: `${ACTION_HEADER_HEIGHT}px`,
  backgroundColor: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.divider}`,
  gap: theme.spacing(3),
}));

export const FlexBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});
