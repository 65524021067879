import { Box, Divider, styled } from '@mui/material';
import {
  SCHEDULER_HEADER_HEIGHT,
  HOUR_HEIGHT,
  ACTION_HEADER_HEIGHT,
} from '../../constants';

export const WeekBox = styled(Box)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  overflowY: 'scroll',
  height: `calc(100% - ${SCHEDULER_HEADER_HEIGHT + ACTION_HEADER_HEIGHT}px)`,
  width: '100%',
});

export const DaysWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  height: '100%',
  width: '100%',
});

export const FullHeightDivider = styled(Divider)({
  height: `${HOUR_HEIGHT * 24}px`,
});
