import { Text } from '@/components/text';
import { getTimeElapsed } from '@/utils/time';
import { Avatar, Box, ListItem } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { INotification } from '../component';

interface INotificationItemProps {
  index: number;
  notification: INotification;
}

const NotificationItem: FC<INotificationItemProps> = ({
  index,
  notification,
}) => {
  const { t } = useTranslation<string>();
  return (
    <ListItem key={index} sx={{ p: 2, borderBottom: '1px solid #bdbdbd' }}>
      <Box display="flex" alignItems="center">
        <Avatar>{notification.teamMemberName[0]}</Avatar>
        <Box ml={2}>
          <Text variant="body1" isBold>
            {notification.teamMemberName} {notification.action}{' '}
            {notification.taskName}
          </Text>
          <Text variant="body2" colorVariant="disabled">
            {getTimeElapsed(
              notification.timestamp,
              t('lang'),
              t('components.header.notification-drawer.yesterday'),
            )}
          </Text>
        </Box>
      </Box>
    </ListItem>
  );
};

export default NotificationItem;
