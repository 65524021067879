import { Theme, createTheme } from '@mui/material';
import { IThemePalette } from './types';

export const customTheme = (themeParam?: IThemePalette): Theme => {
  return createTheme({
    spacing: 8,
    palette: {
      mode: 'light',
      primary: {
        main: themeParam?.primary?.main || '#043e7d',
        light: themeParam?.primary?.light || '#0d53fc',
        dark: themeParam?.primary?.dark || '#1e3058',
      },
      secondary: {
        main: themeParam?.secondary?.main || '#3c539b',
        light: themeParam?.secondary?.light || '#4e6bc9',
        dark: themeParam?.secondary?.dark || '#2a3a6c',
      },
      common: {
        black: '#282828',
        white: '#F6FAF8',
      },
      background: {
        default: themeParam?.background?.default || '#f9fafc',
        paper: themeParam?.background?.paper || '#fff',
      },
      text: {
        primary: themeParam?.text?.primary || '#030303',
        secondary: themeParam?.text?.secondary || '#FFF',
        disabled: themeParam?.text?.disabled || '#616161',
      },
      success: {
        main: '#1fd655',
      },
      error: {
        main: '#e3242b',
      },
    },
  });
};
