import axios from 'axios';
import { removeFromLocalStorage } from '../storage';
import { AUTH_USER_STORAGE_KEY } from './constants';
import { RequestHelpersCollection } from './request-helper';
import { notify } from '../notify';
/**
 * Axios instance that will be used on requests
 */
const axiosInstance = axios.create({
  withCredentials: true,
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 401 || response.status === 403) {
      removeFromLocalStorage(AUTH_USER_STORAGE_KEY);
      window.dispatchEvent(
        new CustomEvent('unauthenticated', {
          detail: {
            message: 'User is not authenticated',
          },
        }),
      );
      notify.error('User is not authenticated');
    }
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401 || error.response.status === 403) {
        removeFromLocalStorage(AUTH_USER_STORAGE_KEY);
        window.dispatchEvent(
          new CustomEvent('unauthenticated', {
            detail: {
              message: 'User is not authenticated',
            },
          }),
        );
      } else if (error.response.status === 402) {
        error.isPaymentRequired = true;
        notify.error('Before login, pay the subscription fee!');
      }
    } else {
      error.isNetworkError = true;
      notify.error('Network error!');
    }
    if (error.response.data?.message) {
      notify.error(error.response.data.message);
    }

    return Promise.reject(error);
  },
);

/**
 * request rapresent a collection of api request helpers that uses the axiosInstance defined.
 */
export const request = new RequestHelpersCollection(axiosInstance, 'template');
export const requestMain = new RequestHelpersCollection(axiosInstance, 'main');
