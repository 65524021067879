import { Text } from '@/components/text';
import CustomDrawer from '@/views/common/custom-drawer';
import { Box, Button, List } from '@mui/material';
import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import notifications from '../../../../mock/notifications.mock.json';
import NotificationIcon from './notification-icon';
import NotificationItem from './notification-item';
import { ViewNotificationsWrapper } from './styled';

export interface INotification {
  teamMemberName: string;
  action: string;
  taskName: string;
  timestamp: string;
}

export interface INotification {
  teamMemberName: string;
  action: string;
  taskName: string;
  timestamp: string;
}

export const Notifications: FC = (): JSX.Element => {
  const { t } = useTranslation<string>();
  const [open, setOpen] = useState(false);
  const anchorEl = useRef<HTMLButtonElement | null>(null);

  const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    anchorEl.current = event.currentTarget;
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <NotificationIcon
        showPulse={notifications.length ? true : false}
        handleClickOpen={handleClickOpen}
      />
      <CustomDrawer
        isOpen={open}
        onClose={handleClose}
        isNotificationDrawer
        content={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '96vh',
              width: '100%',
              overflowY: 'hidden',
            }}
          >
            <Text variant="h4" textAlign="center">
              {t('components.header.notification-drawer.title')}
            </Text>
            <List
              sx={{
                scrollbarWidth: 'thin',
                scrollbarColor: '#888888 #f5f5f5',
                '&::-webkit-scrollbar': {
                  width: '6px',
                  backgroundColor: '#f5f5f5',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#888888',
                  borderRadius: '3px',
                },
                height: '100%',
                width: '100%',
                overflow: 'auto',
              }}
            >
              {notifications.length === 0 ? (
                <Text>
                  {t('components.header.notification-drawer.no-notifs')}
                </Text>
              ) : (
                (notifications as INotification[])
                  .sort(
                    (a, b) =>
                      new Date(b.timestamp).getTime() -
                      new Date(a.timestamp).getTime(),
                  )
                  .map((notification: INotification, index: number) => (
                    <NotificationItem
                      key={`notification-${index}`}
                      {...{ notification, index }}
                    />
                  ))
              )}
            </List>
            <ViewNotificationsWrapper>
              <Button variant="contained">
                <Text colorVariant="secondary">
                  {t('components.header.notification-drawer.button')}
                </Text>
              </Button>
            </ViewNotificationsWrapper>
          </Box>
        }
      />
    </Box>
  );
};
