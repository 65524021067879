import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledDialog } from './styled';
import { CustomDialogProps } from './types';

export const SimpleDialog: React.FC<CustomDialogProps> = ({
  title,
  open,
  onSave,
  children,
  loading = false,
  hasClose = false,
  onClose = undefined,
  confirmButtonLabel = undefined,
  disableAction = false,
  disableBackdropAction,
}) => {
  const { t } = useTranslation<string>();
  const handleSave = (): void => {
    onSave();
  };

  const handleClose = (reason: 'backdropClick' | 'escapeKeyDown') => {
    // If disableBackdrop is true, do not close the dialog on 'backdropClick'
    if (disableBackdropAction && reason === 'backdropClick') {
      return;
    }
    onClose && onClose();
  };

  return (
    <StyledDialog open={open} onClose={(e, reason) => handleClose(reason)}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{loading ? <CircularProgress /> : children}</DialogContent>
      <DialogActions>
        {hasClose && (
          <Button onClick={onClose} color="primary">
            {t('common.cancel')}
          </Button>
        )}
        <Button disabled={disableAction} onClick={handleSave} color="primary">
          {confirmButtonLabel || t('common.save')}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

SimpleDialog.defaultProps = {
  confirmButtonLabel: 'Confirm',
  loading: false,
  hasClose: false,
  onClose: undefined,
  disableAction: false,
};
