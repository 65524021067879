import { FC } from 'react';
import { LoadingbWrapp, Tooth, ToothWrapper } from './styled';
import { tooth } from '@/assets';
import { BoxProps } from '@mui/material';

interface IProps {
  wrapperProps?: BoxProps;
}

export const Loader: FC<IProps> = ({ wrapperProps }) => {
  return (
    <LoadingbWrapp {...wrapperProps}>
      <ToothWrapper>
        <Tooth
          alt="tooth"
          src={tooth}
          style={{ width: '50px', height: 'auto' }}
        />
      </ToothWrapper>
    </LoadingbWrapp>
  );
};
