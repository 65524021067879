import { FC } from 'react';
import { FieldErrors, SubmitHandler, useForm } from 'react-hook-form';
import { StyledButton, StyledInput } from '@/components';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { IFormComponentProps } from './types';
import { IVacation, IVacationSubmit } from '@/types';

export const VacationForm: FC<IFormComponentProps> = ({
  type,
  onSubmit,
  defaultValues,
}) => {
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormComponentProps['defaultValues']>({
    defaultValues,
  });
  const allDayEnabled = watch('allDay');

  const onSubmitForm: SubmitHandler<IFormComponentProps['defaultValues']> = (
    data,
  ) => {
    onSubmit?.(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Grid container spacing={1}>
        <Grid item sm={12}>
          <FormControlLabel
            label="All day"
            control={
              <Checkbox
                checked={allDayEnabled}
                onChange={() => setValue('allDay', !allDayEnabled)}
              />
            }
          />
        </Grid>
        <Grid item sm={12}>
          <StyledInput
            {...register('description', {
              required: 'Description is required',
            })}
            label="Description"
            placeholder="Write the vacancy description here"
            error={!!errors.description}
            helperText={errors.description?.message}
            labelColor="secondary"
          />
        </Grid>
        {type === 'add' ? (
          <>
            <Grid item sm={12}>
              <StyledInput
                {...register('startDate', {
                  required: 'Start date is required',
                })}
                type={allDayEnabled ? 'date' : 'datetime-local'}
                label="Start date"
                placeholder="Write the vacancy description here"
                error={!!(errors as FieldErrors<IVacationSubmit>).startDate}
                helperText={
                  (errors as FieldErrors<IVacationSubmit>).startDate?.message
                }
                labelColor="secondary"
              />
            </Grid>
            <Grid item sm={12}>
              <StyledInput
                {...register('endDate', { required: 'End date is required' })}
                type={allDayEnabled ? 'date' : 'datetime-local'}
                label="End date"
                error={!!(errors as FieldErrors<IVacationSubmit>).endDate}
                helperText={
                  (errors as FieldErrors<IVacationSubmit>).endDate?.message
                }
                placeholder="Write the vacancy description here"
                labelColor="secondary"
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item sm={12}>
              <StyledInput
                {...register('date', { required: 'Date is required' })}
                label="Date"
                error={!!(errors as FieldErrors<IVacation>).date}
                helperText={(errors as FieldErrors<IVacation>).date?.message}
                labelColor="secondary"
                type="date"
              />
            </Grid>
            <Grid item sm={12}>
              <StyledInput
                {...register('startHours', {
                  required: 'Start hours are required',
                })}
                label="Start hours"
                error={!!(errors as FieldErrors<IVacation>).startHours}
                helperText={
                  (errors as FieldErrors<IVacation>).startHours?.message
                }
                labelColor="secondary"
                type="time"
              />
            </Grid>
            <Grid item sm={12}>
              <StyledInput
                {...register('endHours', {
                  required: 'End hours are required',
                })}
                label="End hours"
                error={!!(errors as FieldErrors<IVacation>).endHours}
                helperText={
                  (errors as FieldErrors<IVacation>).endHours?.message
                }
                labelColor="secondary"
                type="time"
              />
            </Grid>
          </>
        )}
        <Grid container item sm={12} justifyContent="center">
          <StyledButton type="submit">Submit</StyledButton>
        </Grid>
      </Grid>
    </form>
  );
};
