import { createContext, useContext } from 'react';
import { IAppointmentsContext } from './types';

export const AppointmentsContext = createContext<IAppointmentsContext>({
  appointments: [],
  setFilterDate: () => {},
  setFilterCategory: () => {},
  setToday: () => {},
  filterDate: '',
  filterCategory: '',
  actionAppointmentsDialog: () => {},
});

export const useAppointmentsContext = (): IAppointmentsContext => {
  return useContext(AppointmentsContext);
};
