import { Box, Theme, styled } from '@mui/material';
import { FC } from 'react';
import { CustomPageWrapperProps } from './component';

const StyledPageWrapper = styled(Box as FC<CustomPageWrapperProps>, {
    shouldForwardProp: (prop) =>
        !['maxWidth', 'column'].includes(
            prop as string,
        ),
})(
    ({
        theme,
        maxWidth,
        column
    }: CustomPageWrapperProps & { theme?: Theme }) => ({
        width: '100%',
        maxWidth: maxWidth,
        display: 'flex',
        flexDirection: column ? 'column' : 'row',
    }),
);

export default StyledPageWrapper;
