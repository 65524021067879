import { createContext, useContext } from 'react';
import { IFormContext } from './types';

export const FormContext = createContext<IFormContext>({
  activeStep: 0,
  formData: {},
  isFormValid: false,
  resetForm: () => {},
  setActiveStep: () => {},
  setFormData: () => {},
  setIsFormValid: () => {},
  updateFormData: () => {},
});

export const useFormContext = () => useContext(FormContext);
