import { Dialog, DialogProps, styled } from '@mui/material';
import { FC } from 'react';

const StyledDialog = styled(Dialog as FC<DialogProps>)(({ theme }) => ({
  '.MuiPaper-root': {
    width: '100%',
    height: '100%',
    minWidth: '300px',
    minHeight: '400px',
    overflow: 'auto',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '640px',
      maxHeight: '600px',
    },
    [theme.breakpoints.down('md')]: {
      minHeight: '100%',
      border: 'none',
      margin: 0,
    },
    '.MuiDialogContent-root': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
  },
  '.MuiDialog-container': {},
}));

export { StyledDialog };
