import { FC } from 'react';
import {
  styled,
  BoxProps,
  Box,
  TableCell,
  tableCellClasses,
  TableRow,
  alpha,
} from '@mui/material';

export const TableWrapper: FC<BoxProps> = styled(Box as FC<BoxProps>)(
  ({ theme }) => ({
    width: '100%',
    margin: 0,
    boxShadow: `${alpha(theme?.palette.primary.main, 0.1)} 0px 0px 20px 3px`,
    borderTopRightRadius: '10px',
    borderTopLeftRadius: '10px',
    '.MuiTablePagination-toolbar': {
      borderTop: `1px solid ${theme.palette.grey[300]}`,
    },
    '&:hover': {
      boxShadow: `${alpha(theme?.palette.primary.main, 0.5)} 0px 0px 20px 3px`,
    },
  }),
);

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: alpha(theme.palette.primary.main, 0.4),
    height: '50px',
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    height: '48px',
    fontSize: 16,
    color: theme.palette.grey[700],
  },
}));

export const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    background: '#F9F9FC',
  },
  '&:nth-of-type(even)': {
    background: '#fff',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 2,
  },
}));

export const SearchBoxWrap: FC<BoxProps> = styled(Box as FC<BoxProps>)(
({ theme }) => ({
    backgroundColor: alpha(theme.palette.primary.light, 0.3),
    padding: theme.spacing(2),
    borderRadius: '10px 10px 0 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
}));

export const AlphaBoxWrap: FC<BoxProps> = styled(Box as FC<BoxProps>)(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '16px'
}));

