import { useAuthContext } from '@/providers';
import { Outlet, Navigate } from 'react-router-dom';
import { IRouteProps } from '../types';
import { FC } from 'react';
import { AuthContentWrapper } from './styled';

export const AuthRoute: FC<IRouteProps> = ({ redirectPath }) => {
  const { user } = useAuthContext();
  if (user?.role === undefined && user?.connection === 'offline')
    return (
      <AuthContentWrapper>
        <Outlet />
      </AuthContentWrapper>
    );

  return <Navigate to={redirectPath} replace />;
};

export const RedirectToAuth: FC = () => <Navigate to="/auth/sign-in" replace />;
