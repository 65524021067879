import { FC } from 'react';
import { Box, Grid, styled } from '@mui/material';
import { IMonthDayGridItemProps } from './types';
import { ACTION_HEADER_HEIGHT } from '../../constants';

export const MonthGridContainer = styled(Grid)({
  height: `calc(100% - ${ACTION_HEADER_HEIGHT}px)`,
});

export const MonthDayGridItem = styled(Grid as FC<IMonthDayGridItemProps>, {
  shouldForwardProp: (prop) =>
    !['isInCurrentMonth', 'borders'].includes(prop as string),
})(({ theme, isInCurrentMonth, borders }) => ({
  width: '100%',
  height: `calc(100% / 5)`,
  opacity: isInCurrentMonth ? 1 : 0.5,
  ...(borders.includes('top') && {
    borderTop: `1px solid ${theme.palette.divider}`,
  }),
  ...(borders.includes('right') && {
    borderRight: `1px solid ${theme.palette.divider}`,
  }),
  ...(borders.includes('left') && {
    borderLeft: `1px solid ${theme.palette.divider}`,
  }),
  ...(borders.includes('bottom') && {
    borderBottom: `1px solid ${theme.palette.divider}`,
  }),
}));

export const AppointmentsBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '95%',
  overflow: 'hidden',
});

export const AppointmentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  overflow: 'hidden',
  borderRadius: theme.spacing(1),
  cursor: 'pointer',
  paddingRight: 5,
  ':before': {
    display: 'inline-flex',
    alignItems: 'center',
    // @ts-ignore
    content: '""',
    borderRadius: '50%',
    height: 5,
    width: 5,
    margin: '0px  8px',
    backgroundColor: theme.palette.primary.light,
  },
  ':hover': {
    backgroundColor: `${theme.palette.primary.light}10`,
  },
}));
