import { FC, Ref, useEffect, useState, useRef } from 'react';
import { CurrentTimeIndicator } from './styled';
import { ITimeIndicatorProps } from './types';

export const TimeIndicator: FC<ITimeIndicatorProps> = ({ selectedDate }) => {
  const [time, setTime] = useState<Date>(new Date());
  const timeIndicatorRef = useRef<HTMLHRElement>(null);

  useEffect(() => {
    // Updating the time state every 10 seconds only to avoid being too exhaustive
    const timeInterval = setInterval(() => setTime(new Date()), 10000);

    return () => {
      clearInterval(timeInterval);
    };
  }, []);

  useEffect(() => {
    timeIndicatorRef.current?.scrollIntoView({
      block: 'center',
      inline: 'center',
      behavior: 'instant',
    });
  }, [selectedDate]);

  return (
    <CurrentTimeIndicator
      ref={timeIndicatorRef as Ref<HTMLHRElement>}
      currentTime={time.toISOString()}
      selectedDate={selectedDate}
    />
  );
};
