import { FC } from 'react';
import { SchedulerLoaderBox } from './styled';
import { CircularProgress } from '@mui/material';

export const SchedulerLoader: FC = () => {
  return (
    <SchedulerLoaderBox>
      <CircularProgress />
    </SchedulerLoaderBox>
  );
};
