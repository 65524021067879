import { FC, useMemo, Fragment } from 'react';
import { FullHeightDivider, DaysWrapper, WeekBox } from './styled';
import { Day } from '../day/component';
import { IWeekProps } from './types';
import { WEEKLY_DAYS, getWeekDates } from '@/utils';
import { Timeline } from '../timeline/component';
import { WeekHeader } from './week-header/component';

export const Week: FC<IWeekProps> = ({ selectedDate }) => {
  const weekDates = useMemo(
    () => getWeekDates(selectedDate).map((date) => ({ date })),
    [selectedDate],
  );

  return (
    <>
      <WeekHeader items={weekDates} />
      <WeekBox>
        <Timeline />
        <DaysWrapper>
          {WEEKLY_DAYS.map((day, i) => (
            <Fragment key={`scheduler-week-day-${day}`}>
              <Day selectedDate={weekDates[i].date} />
              {i !== WEEKLY_DAYS.length - 1 && (
                <FullHeightDivider flexItem orientation="vertical" />
              )}
            </Fragment>
          ))}
        </DaysWrapper>
      </WeekBox>
    </>
  );
};
