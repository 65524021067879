import { Text } from '@/components';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSchedulerPropsContext } from '../../provider/context';
import { SchedulerLoader } from '../scheduler-loader/component';
import { aggregateAndFilterAppointments } from './helpers';
import { AppointmentBox, SiderBox } from './styled';

export const Sider: FC = () => {
  const { t } = useTranslation<string>();
  const { appointments, loading, onUpdate } = useSchedulerPropsContext();
  const onComingAppointments = useMemo(
    () => aggregateAndFilterAppointments(appointments || {}),
    [appointments],
  );

  return (
    <SiderBox gap={1}>
      <Text sx={{ px: 0 }} variant="h4">
        {t('pages.common.calendar.scheduler.sider.title')}
      </Text>
      {loading ? (
        <SchedulerLoader />
      ) : (
        onComingAppointments.map((appointment) => (
          <AppointmentBox
            key={`scheduler-sider-appointment-${appointment.date}-${appointment.startHours}`}
            onClick={() => onUpdate && onUpdate(appointment)}
          >
            <Text>
              <strong>
                {appointment.type === 'vacation' && t('common.vacation')}
              </strong>
              {appointment.description}
            </Text>
            {appointment.type === 'appointment' && (
              <Text>
                {appointment.patient.firstName} {appointment.patient.lastName}
              </Text>
            )}
            <Text>{appointment.startHours}</Text>
          </AppointmentBox>
        ))
      )}
    </SiderBox>
  );
};
