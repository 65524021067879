import { Box, BoxProps, styled } from '@mui/material';
import { FC } from 'react';

export const AuthContentWrapper: FC<BoxProps> = styled(Box as FC<BoxProps>)(
  ({ theme }) => ({
    width: '100%',
    maxWidth: '500px',
    height: '100%',
    maxHeight: '500px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '24px',

    backgroundColor: `${theme.palette.primary.main}`,
    color: `${theme.palette.text.secondary}`,
    borderRadius: '24px',
    padding: '36px',

    border: '2px solid #4682A9',
  }),
);
