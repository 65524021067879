import { FC, useState } from 'react';
import { IDatePickerDialogProps, IDatePickerIconButtonProps } from './types';
import { IconButton, Tooltip } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { CalendarMonth } from '@mui/icons-material';

const DatePickerIconButton: FC<IDatePickerIconButtonProps> = ({
  setOpen,
  InputProps,
  id,
}) => (
  <Tooltip title="Calendar">
    <IconButton
      id={id}
      onClick={() => setOpen?.((prev) => !prev)}
      ref={InputProps?.ref}
    >
      <CalendarMonth />
    </IconButton>
  </Tooltip>
);

export const DatePickerDialog: FC<IDatePickerDialogProps> = ({
  selectedDate,
  onSelectedDateChange,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        slots={{ field: DatePickerIconButton }}
        slotProps={{ field: { setOpen } as any }}
        value={dayjs(selectedDate)}
        onChange={(date) =>
          onSelectedDateChange(date?.toDate().toISOString() as string)
        }
      />
    </LocalizationProvider>
  );
};
