import { FC } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { IEditAppointmentDialogProps, IEditAppointmentForm } from './types';
import { AppointmentForm, VacationForm } from '@/components';
import { appointmentFormDefaultValues } from './defaultValues';
import { IAppointmentSubmit, IVacation, IVacationSubmit } from '@/types';

export const EditAppointmentDialog: FC<IEditAppointmentDialogProps> = ({
  open,
  appointment,
  onUpdateAppointment,
  onUpdateVacation,
  onDismiss,
}) => {
  if (!appointment) return;

  return (
    <Dialog open={typeof open === 'boolean' ? open : true} onClose={onDismiss}>
      <DialogTitle>Schedule</DialogTitle>
      <DialogContent>
        {appointment.type === 'appointment' ? (
          <AppointmentForm
            defaultValues={appointmentFormDefaultValues(appointment)}
            onSubmit={
              onUpdateAppointment as (
                data: IAppointmentSubmit | IEditAppointmentForm,
              ) => void
            }
          />
        ) : (
          <VacationForm
            type="edit"
            defaultValues={appointment as IVacation}
            onSubmit={
              onUpdateVacation as (data: IVacationSubmit | IVacation) => void
            }
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
