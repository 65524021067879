import env_config from '@/env.config';
import { IRequestHelpersCollection } from './types';
import { AxiosInstance } from 'axios';
import { decryptData, encryptData } from '../encrypt';
import { encryptURL } from '../encrypt/encrypt';

export type BaseUrl = 'template' | 'main';

export class RequestHelpersCollection implements IRequestHelpersCollection {
  private instance: AxiosInstance;
  private baseUrl?: BaseUrl;

  constructor(instance: AxiosInstance, baseUrl?: BaseUrl) {
    this.instance = instance;
    this.baseUrl = baseUrl;
  }

  /**
   * Private function to get the base url for each request made
   */
  private getRequestBaseUrl() {
    switch (this.baseUrl) {
      case 'template':
        if (env_config.api.url) {
          return env_config.api.url;
        } else {
          throw new Error(
            'getRequestBaseUrl: urls are missing from environment.',
          );
        }
      case 'main':
        if (env_config.api.mainUrl) {
          return env_config.api.mainUrl;
        } else {
          throw new Error(
            'getRequestBaseUrl: urls are missing from environment.',
          );
        }
      default:
        throw new Error('getRequestBaseUrl: baseUrl is not valid.');
    }
  }

  public async get<T>(path: string, query?: any): Promise<T> {
    if (!env_config.encryption_api)
      throw new Error('request.get: api encryption secret is missing.');

    if (query) {
      const data = encryptURL(JSON.stringify(query), env_config.encryption_api);
      path += `?data=${data}`;
    }

    const response = await this.instance.get(this.getRequestBaseUrl() + path);

    if (response.data) {
      const decryptedResponse = decryptData(
        response.data.data as string,
        env_config.encryption_api,
      );

      return decryptedResponse as T;
    } else {
      throw new Error(`request.get - ${path}: data is missing from response.`);
    }
  }

  public async post<T = boolean>(
    path: string,
    data?: NonNullable<unknown>,
  ): Promise<T> {
    if (!env_config.encryption_api)
      throw new Error('request.post: api encryption secret is missing.');

    const encryptedBody = data && encryptData(data, env_config.encryption_api);

    const response = await this.instance.post(this.getRequestBaseUrl() + path, {
      data: encryptedBody,
    });

    if (response?.data?.data) {
      const decryptedResponse = decryptData(
        response.data.data as string,
        env_config.encryption_api,
      );

      return decryptedResponse as T;
    } else {
      return true as T;
    }
  }

  public async patch<T = boolean>(
    path: string,
    data: NonNullable<unknown>,
  ): Promise<T> {
    if (!env_config.encryption_api)
      throw new Error('request.patch: api encryption secret is missing.');

    const encryptedBody = encryptData(data, env_config.encryption_api);

    const response = await this.instance.patch(
      this.getRequestBaseUrl() + path,
      {
        data: encryptedBody,
      },
    );

    if (response?.data) {
      const decryptedResponse = decryptData(
        response.data as string,
        env_config.encryption_api,
      );

      return decryptedResponse as T;
    } else {
      return true as T;
    }
  }

  public async delete<T = boolean>(path: string): Promise<T> {
    const response = await this.instance.delete(
      this.getRequestBaseUrl() + path,
    );

    if (response?.data) {
      if (!env_config.encryption_api)
        throw new Error('request.delete: api encryption secret is missing.');

      const decryptedResponse = decryptData(
        response.data as string,
        env_config.encryption_api,
      );

      return decryptedResponse as T;
    } else {
      return true as T;
    }
  }
}
