import { AppointmentForm, VacationForm } from '@/components';
import { IVacation, IVacationSubmit } from '@/types';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
} from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  appointmentFormDefaultValues,
  vacationFormDefaultValues,
} from './defaultValues';
import { IAddAppointmentDialogProps, ScheduleType } from './types';

const scheduleTypes: ScheduleType[] = ['appointment', 'vacation'];

export const AddAppointmentDialog: FC<IAddAppointmentDialogProps> = ({
  open,
  presetDate,
  onDismiss,
  withVacation,
  onCreateAppointment,
  onCreateVacation,
}) => {
  const { t } = useTranslation<string>();
  const [scheduleType, setScheduleType] = useState<ScheduleType>('appointment');

  return (
    <Dialog open={typeof open === 'boolean' ? open : true} onClose={onDismiss}>
      <DialogTitle>
        <Box width="100%" display="flex" justifyContent="space-between">
          {t('pages.common.calendar.scheduler.schedule-pop-up.title')}
          <Select
            disabled={!withVacation}
            value={scheduleType}
            onChange={(e) => setScheduleType(e.target.value as ScheduleType)}
          >
            {scheduleTypes.map((item) => (
              <MenuItem
                key={`add-appointment-dialog-scheduleType-${item}`}
                value={item}
              >
                {t(`common.${item}`)}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </DialogTitle>
      <DialogContent>
        {scheduleType === 'appointment' ? (
          <AppointmentForm
            defaultValues={appointmentFormDefaultValues(presetDate)}
            onSubmit={onCreateAppointment}
          />
        ) : (
          <VacationForm
            type="add"
            defaultValues={vacationFormDefaultValues(presetDate)}
            onSubmit={
              onCreateVacation as (data: IVacationSubmit | IVacation) => void
            }
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
