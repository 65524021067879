import { AppointmentCategoryType, IAppointment, IVacation } from '@/types';
import { useFilteredAppointments } from '../use-filtered-appintments';
import { MOCK_APPOINTMENT } from '@/mock';
import { generateMockDate } from '@/utils';
import { useMemo } from 'react';

export interface IUseAppointments {
  filterDate: string;
  filterCategory: AppointmentCategoryType | null;
}

interface UseAppointmentsReturnType {
  appointments: (IAppointment | IVacation)[];
}

export const useAppointments = ({
  filterCategory,
  filterDate,
}: IUseAppointments): UseAppointmentsReturnType => {
  const temp = MOCK_APPOINTMENT as (IAppointment | IVacation)[];

  /**
   *
   * This adaptation is form mock data to update the appointments
   * without changing the json file every day
   *
   * After the BE side is ready, remove this
   */
  const appointments = useMemo(() => {
    const myData = temp.map((el) => ({
      ...el,
      date: generateMockDate(),
    }));

    return [...myData, ...temp];
  }, [temp]);

  const filteredAppointments = useFilteredAppointments(
    appointments,
    filterDate,
    filterCategory,
  );

  return {
    appointments: filteredAppointments,
  };
};
