import { FC, ReactElement, useId } from 'react';
import { Tooltip } from '@mui/material';
import { t } from 'i18next';
import { Favorite, FavoriteBorder } from '@mui/icons-material';

export interface PinToggleProps {
  onClick: () => void;
  pinned?: boolean;
  tooltip?: string;
}

interface WrapperProps {
  tooltip: PinToggleProps['tooltip'];
  children: ReactElement;
}

const Wrapper: FC<WrapperProps> = ({ tooltip, children }): JSX.Element =>
  tooltip ? (
    <Tooltip title={tooltip}>{children as ReactElement}</Tooltip>
  ) : (
    children
  );

export const PinToggle: FC<PinToggleProps> = ({
  onClick,
  pinned,
  tooltip,
}): JSX.Element => {
  const id = useId();

  return (
    <Wrapper tooltip={(pinned ? tooltip : t('add_to_favorites')) as string}>
      {pinned ? (
        <Favorite
          id={id}
          className="fav_icon"
          onClick={(e): void => {
            e.preventDefault();
            e.stopPropagation();
            onClick();
          }}
        />
      ) : (
        <FavoriteBorder
          id={id}
          className="fav_icon"
          onClick={(e): void => {
            e.preventDefault();
            e.stopPropagation();
            onClick();
          }}
        />
      )}
    </Wrapper>
  );
};

PinToggle.defaultProps = {
  pinned: false,
  tooltip: '',
};
