import React, { useState, useEffect } from 'react';
import { IFormProviderProps } from './types';
import { FormContext } from './context';

export const FormProvider: React.FC<IFormProviderProps> = ({ children }) => {
  const [formData, setFormData] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [isFormValid, setIsFormValid] = useState(false);

  const updateFormData = (newData: any) => {
    setFormData((prevData) => {
      const updatedData = { ...prevData, ...newData };
      sessionStorage.setItem('form-data', JSON.stringify(updatedData));
      return updatedData;
    });
  };

  useEffect(() => {
    const savedFormData = sessionStorage.getItem('form-data');
    if (savedFormData) {
      setFormData(JSON.parse(savedFormData));
    }
  }, []);

  const resetForm = () => {
    setFormData({});
    sessionStorage.removeItem('form-data');
  };

  return (
    <FormContext.Provider
      value={{
        formData,
        setFormData,
        activeStep,
        setActiveStep,
        isFormValid,
        setIsFormValid,
        resetForm,
        updateFormData,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
