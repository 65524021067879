import { MainLayout, SideDrawer } from '@/components';
import { useAuthContext } from '@/providers';
import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import DoctorRoutes from '../doctor-route/doctorRoutes.json';
import { IRouteProps } from '../types';

export const CommonRoute: FC<IRouteProps> = ({ redirectPath }) => {
  const { user } = useAuthContext();

  if (
    (user?.role === 'assistant' || user?.role === 'doctor') &&
    user?.connection === 'online'
  )
    return (
      <MainLayout>
        <SideDrawer customTabs={user?.role === 'doctor' ? DoctorRoutes : []} />
        <Outlet />
      </MainLayout>
    );

  return <Navigate to={redirectPath} replace />;
};
