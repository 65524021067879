import { createSlice } from '@reduxjs/toolkit';
import { IActivateSlice } from './types';

const initialState: IActivateSlice = {
  step: 0,
};

const activateSlice = createSlice({
  name: 'activate',
  initialState,
  reducers: {
    activateNextStep(state) {
      state.step++;
    },
  },
});

export const activateReducer = activateSlice.reducer;
export const activateActions = activateSlice.actions;
