import { styled } from '@mui/material';
import { FC, ReactNode } from 'react';

export const Wrapper: FC<{ children: ReactNode }> = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    '.MuiSvgIcon-root': {
      color: theme.palette.text.primary,
    },
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }),
);
