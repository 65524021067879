import { Text } from '@/components/text';
import { useAuthContext } from '@/providers';
import { actions, useAppDispatch } from '@/utils';
import { Avatar, Box, IconButton, Menu, MenuItem } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { headerMenuData } from '../header-menu-data/headerMenu.data';
import HeaderMenuItemsWrapper from './styled';

const HeaderMenu: FC = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation<string>();

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const navigateSettings = () => {
    navigate('/common/settings');
  };

  const handleLanguage = () => {
    dispatch(actions.changeLanguage());
  };

  const handleAction = (name: string): void => {
    switch (name) {
      case 'settings':
        navigateSettings();
        break;
      case 'logout':
        signOut();
        break;
      case 'language':
        handleLanguage();
        break;
      default:
        signOut();
    }
  };

  const { signOut } = useAuthContext();

  return (
    <Box>
      <IconButton
        aria-controls="menu-appbar"
        aria-haspopup="true"
        aria-label="avatar-button"
        onClick={handleMenuOpen}
        color="inherit"
      >
        <Avatar />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {headerMenuData.map((item) => (
          <MenuItem
            onClick={() => handleAction(item.name)}
            key={item.name}
            sx={{
              minWidth: '125px',
              p: 1,
            }}
          >
            <HeaderMenuItemsWrapper>
              <Box width="30px" height="30px" m={0}>
                {item.icon()}
              </Box>
              <Text>
                {t(`components.header.profile-icon-dropdown.${item.name}`)}
              </Text>
            </HeaderMenuItemsWrapper>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default HeaderMenu;
