import { notify } from '@/utils';
import { FC, ReactNode, useEffect } from 'react';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

export const NotificationsProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  useEffect(() => {
    notify.enable(
      (message: string) => enqueueSnackbar(message, { variant: 'error' }),
      (message: string) => enqueueSnackbar(message, { variant: 'warning' }),
      (message: string) => enqueueSnackbar(message, { variant: 'info' }),
      (message: string) => enqueueSnackbar(message, { variant: 'success' }),
    );
  }, []);

  return (
    <>
      <SnackbarProvider
        preventDuplicate
        autoHideDuration={3500}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      />
      {children}
    </>
  );
};
