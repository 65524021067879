import { activateReducer, activateActions } from './activate/slice';
import { utilsReducer, utilsActions } from './utils/slice';

export const reducers = {
  utils: utilsReducer,
  activate: activateReducer,
};

export const actions = {
  ...activateActions,
  ...utilsActions,
};
