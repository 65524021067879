import { FC } from 'react';
import {
  Box,
  BoxProps,
  List,
  ListProps,
  Tooltip,
  TooltipProps,
  styled,
} from '@mui/material';

export const StatusBox = styled(Box as FC<BoxProps>)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  padding: theme.spacing(0, 1),
}));

export const PreviewList = styled(List as FC<ListProps>)(({ theme }) => ({
  '.MuiListItem-container': {
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(1, 0),
    position: 'relative',
    alignItems: 'center',
  },
  '.MuiListItemIcon-root': {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  '.MuiListItemSecondaryAction-root': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  '.MuiListItemText-primary': {
    maxWidth: '60%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  },
}));

export const PreviewImg = styled('img')(() => ({
  maxWidth: '400px',
  maxHeight: '400px',
  objectFit: 'contain',
  borderRadius: '16px',
}));

export const PreviewContainer: FC<TooltipProps> = (props) => (
  <Tooltip
    placement="bottom-start"
    {...props}
    PopperProps={{
      keepMounted: true,
      sx: {
        '.MuiTooltip-tooltip': {
          maxWidth: '420px',
          width: 'auto',
          maxHeight: '450px',
          overflow: 'hidden',
          padding: '8px',
        },
      },
    }}
  />
);
