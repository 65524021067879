import React, { useEffect, useState } from 'react';
import { CustomTable } from '@/components';

type ITypeAlign = 'left' | 'right' | 'center';

interface TableColumn {
  key: string;
  width: string | number;
  label: string;
  align: ITypeAlign;
}

interface InterventionFormValues {
  interventionId: string;
  interventionType: string;
  dentistAssigned: string;
  interventionStatus: string;
  productName: string;
  quantityRequired: number;
  category: string;
  unitPrice: number;
}

interface InterventionTableProps {
  interventions: InterventionFormValues[];
}

export const InterventionTable: React.FC<InterventionTableProps> = ({
  interventions,
}) => {
  const [rows, setRows] = useState<InterventionFormValues[]>([]);
  console.log('setRows', setRows);

  const tableColumns: TableColumn[] = [
    {
      key: 'interventionId',
      width: 'auto',
      label: 'Intervention ID',
      align: 'left',
    },
    { key: 'interventionType', width: 'auto', label: 'Type', align: 'left' },
    {
      key: 'dentistAssigned',
      width: 'auto',
      label: 'Dentist Assigned',
      align: 'left',
    },
    {
      key: 'interventionStatus',
      width: 'auto',
      label: 'Status',
      align: 'left',
    },
    { key: 'category', width: 'auto', label: 'Category', align: 'left' },
    {
      key: 'unitPrice',
      width: 'auto',
      label: 'Unit Price',
      align: 'right',
    },
    {
      key: 'interventionStatus',
      width: 'auto',
      label: 'Status',
      align: 'right',
    },
  ];
  useEffect(() => {
    setRows(interventions);
  }, [interventions]);

  return (
    <div>
      <CustomTable
        data={{
          rows: rows,
          columns: tableColumns,
        }}
        loading={false}
        pagination={true}
        searchTable={true}
      />
    </div>
  );
};
