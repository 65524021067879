import { FC, ReactNode } from 'react';
import { ISchedulerProps } from '../types';
import { SchedulerPropsContext } from './context';

export const SchedulerPropsProvider: FC<{
  children: ReactNode;
  propsValue: ISchedulerProps;
}> = ({ children, propsValue }) => {
  return (
    <SchedulerPropsContext.Provider value={propsValue}>
      {children}
    </SchedulerPropsContext.Provider>
  );
};
