import env_config from '@/env.config';
import { decryptData, encryptData } from '../encrypt';

/**
 * Function that helps saving data to local storage in a safe way
 *
 * @param key string - represents local storage storing key
 * @param data unknown - represents data to be stored
 */
export function saveToLocalStorage(
  key: string,
  data: NonNullable<unknown>,
): void {
  if (!env_config.encryption_local_storage)
    throw new Error('saveToLocalStorage: encryption secret key is missing.');

  const encryptedData = encryptData(data, env_config.encryption_local_storage);
  localStorage.setItem(key, encryptedData);
}

/**
 * Function that helps reading data from local storage
 *
 * @param key string - represents local storage storing key
 * @param data unknown - represents data to be read
 * @returns parsed data of type T
 */
export function readFromLocalStorage<T>(key: string): T | undefined {
  if (!env_config.encryption_local_storage)
    throw new Error('readFromLocalStorage: encryption secret key is missing.');

  const stringifiedData = localStorage.getItem(key);
  if (!stringifiedData) {
    return;
  }

  const decryptedData = decryptData(
    stringifiedData,
    env_config.encryption_local_storage,
  );
  if (!decryptedData) throw new Error('Failing during data decryption.');

  return decryptedData as T;
}

/**
 * Function that helps removing data from local storage
 *
 * @param key string - represents local storage storing key
 */
export function removeFromLocalStorage(key: string): void {
  localStorage.removeItem(key);
}
