import { FC, useState, useEffect, useCallback } from 'react';
import { IOnboardingProviderProps } from './types';
import { useAuthContext } from '../auth';
import { SimpleDialog } from '@/components';

export const OnboardingProvider: FC<IOnboardingProviderProps> = ({
  children,
  components,
}) => {
  // -2 - onboarding not showed
  // -1 - onboarding on the feet to go
  //>=0 - onboarding started
  const [currentComponentIndex, setCurrentComponentIndex] =
    useState<number>(-2);
  const CurrentComponent = components[currentComponentIndex];
  const { user } = useAuthContext();

  useEffect(() => {
    if (!user?.onboarding_completed) setCurrentComponentIndex(-1);
  }, [user?.onboarding_completed]);

  const onComplete = useCallback(async () => {
    if (currentComponentIndex < components.length - 1)
      setCurrentComponentIndex((prev) => prev + 1);
    else if (currentComponentIndex) {
      // call server to set onboarding completed on user
      await new Promise((res) => setTimeout(() => res(true), 500));
      setCurrentComponentIndex(-2);
    }
  }, [currentComponentIndex, components]);

  return (
    <>
      {
        <SimpleDialog
          open={currentComponentIndex === -1}
          confirmButtonLabel="Start"
          onSave={() => setCurrentComponentIndex(0)}
        >
          Hi "{user?.role} {user?.email}", before you start using our platform
          there are some steps to follow. Click the below button to start.
        </SimpleDialog>
      }
      {CurrentComponent && <CurrentComponent onComplete={onComplete} />}
      {children}
    </>
  );
};
