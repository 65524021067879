import { Box, BoxProps } from '@mui/material';
import { styled, keyframes, alpha } from '@mui/material/styles';
import { FC } from 'react';

// Define the keyframes for the wave animation
const waveAnimation = keyframes`
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
`;

// Define the keyframes for the fill background animation
const fillBackground = keyframes`
  0% {
    top: 60%
  }
  30% {
    top: 40%
  }
  80% {
    top: 10%
  }
  100% {
    top: -40%
  }
`;

// Styled component with animations using MUI
export const ToothWrapper = styled(Box as FC<BoxProps>)(({ theme }) => ({
  width: '90px',
  height: '90px',
  borderRadius: '50%',
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  overflow: 'hidden',
  zIndex: 2,
  '&::before': {
    content: '""',
    position: 'absolute',
    width: '200%',
    height: '200%',
    top: '60%',
    left: '0%',
    backgroundColor: alpha(theme.palette.primary.main, 0.5),
    mask: `
    radial-gradient(20px at 50% 5px, #0000 99%, #000 101%) 
      50% 10px/calc(5 * 5px) 100% repeat-x
      `,
    animationName: `${waveAnimation}, ${fillBackground}`,
    animationDuration: '3s, 3s',
    animationTimingFunction: 'ease-in-out',
    animationFillMode: 'forwards',
    zIndex: 3,
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    width: '200%',
    height: '200%',
    top: '60%',
    left: '0%',
    backgroundColor: theme.palette.primary.main,
    mask: `
    radial-gradient(20px at 50% 5px, #0000 99%, #000 101%) 
      50% 10px/calc(5 * 5px) 100% repeat-x
      `,
    animationName: `${waveAnimation}, ${fillBackground}`,
    animationDuration: '3s, 3s',
    animationTimingFunction: 'ease-in-out',
    animationDelay: '.5s, 0.3s',
    animationFillMode: 'forwards',
    zIndex: 4,
  },
}));

export const Tooth = styled('img')(() => ({
  width: '50px',
  height: 'auto',
  objectFit: 'contain',
  position: 'absolute',
  zIndex: 5,
}));

export const LoadingbWrapp = styled(Box as FC<BoxProps>)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '90px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
