import { ViewMode } from '@/components/scheduler/types';
import { MONTHS, capitalize, getWeekStartEndDates } from '@/utils';
import { DateHandlersPerViewModeCollection, IDateHandlers } from './types';

export const getFormattedDatePerViewMode: Record<
  ViewMode,
  (date: string, lang: string) => string
> = {
  [ViewMode.Day]: (dateIso: string, lang: string) =>
    capitalize(
      new Intl.DateTimeFormat(lang, { dateStyle: 'full' }).format(
        new Date(dateIso),
      ),
    ),
  [ViewMode.Week]: (dateIso: string, lang: string) => {
    const weekStartEndDates = getWeekStartEndDates(dateIso);
    return new Intl.DateTimeFormat(lang, { dateStyle: 'medium' }).formatRange(
      weekStartEndDates.startDate,
      weekStartEndDates.endDate,
    );
  },
  [ViewMode.Month]: (dateIso: string) => {
    const date = new Date(dateIso);
    return capitalize(`${MONTHS[date.getUTCMonth()]} ${date.getFullYear()}`);
  },
};

const dayDateHandlers: IDateHandlers = {
  next: (setter, current) => {
    const date = new Date(current);
    date.setDate(date.getDate() + 1);
    setter(date.toISOString());
  },
  previous: (setter, current) => {
    const date = new Date(current);
    date.setDate(date.getDate() - 1);
    setter(date.toISOString());
  },
};

const weekDateHandlers: IDateHandlers = {
  next: (setter, current) => {
    const date = new Date(current);
    date.setDate(date.getDate() + 7);
    setter(date.toISOString());
  },
  previous: (setter, current) => {
    const date = new Date(current);
    date.setDate(date.getDate() - 7);
    setter(date.toISOString());
  },
};

const monthDateHandlers: IDateHandlers = {
  next: (setter, current) => {
    const date = new Date(current);
    date.setMonth(date.getMonth() + 1);
    setter(date.toISOString());
  },
  previous: (setter, current) => {
    const date = new Date(current);
    date.setMonth(date.getMonth() - 1);
    setter(date.toISOString());
  },
};

export const dateHandlersPerViewMode: DateHandlersPerViewModeCollection = {
  [ViewMode.Day]: dayDateHandlers,
  [ViewMode.Week]: weekDateHandlers,
  [ViewMode.Month]: monthDateHandlers,
};
