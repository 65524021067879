import CustomDrawer from '@/views/common/custom-drawer';
import {
  IDrawerState,
  drawerInitialState,
} from '@/views/patients/patient-profile/teeth-schema/component';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React, { SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NotesListContent from '../notes-list';
import { Text } from '../text';

interface Note {
  text: string;
  timestamp: Date;
}

interface NoteActionState {
  type: 'add' | 'edit' | null;
  index: number | null;
  note: string;
}

interface IToothNotesSelect {
  notes: Note[];
  drawerOpen: IDrawerState;
  selectedToothId: string | null;
  setDrawerOpen: React.Dispatch<SetStateAction<IDrawerState>>;
  editNote: (noteTimestamp: Date, newText: string) => void;
  addNotes: (noteText: string) => void;
  deleteNote: (noteTimestamp: Date) => void;
}

export const ToothNotesSelect: React.FC<IToothNotesSelect> = ({
  selectedToothId,
  notes,
  editNote,
  drawerOpen,
  setDrawerOpen,
  addNotes,
  deleteNote,
}) => {
  const { t } = useTranslation<string>();
  const [noteAction, setNoteAction] = useState<NoteActionState>({
    type: null,
    index: null,
    note: '',
  });
  const [deleteConfirmation, setDeleteConfirmation] = useState<{
    open: boolean;
    index: number | null;
  }>({ open: false, index: null });

  const handleNoteChange = (note: string) => {
    setNoteAction((prevState) => ({ ...prevState, note }));
  };

  const handleAddNotes = (noteText: string): void => {
    addNotes(noteText);
    setNoteAction({ type: null, index: null, note: '' });
  };

  const startEdit = (index: number) => {
    setNoteAction({ type: 'edit', index, note: notes[index].text });
  };

  const saveEdit = (editedText: string) => {
    if (noteAction.index !== null) {
      const updatedNotes = notes.find((_, index) => index === noteAction.index);
      updatedNotes?.timestamp && editNote(updatedNotes?.timestamp, editedText);
      setNoteAction({ type: null, index: null, note: '' });
    }
  };

  const openDeleteDialog = (index: number) => {
    setDeleteConfirmation({ open: true, index });
  };

  const confirmDelete = () => {
    if (deleteConfirmation.index !== null) {
      const updatedNotes = notes.find(
        (_, index) => index === deleteConfirmation.index,
      );
      updatedNotes?.timestamp && deleteNote(updatedNotes?.timestamp);
      setDeleteConfirmation({ open: false, index: null });
    }
  };

  const handleClose = (): void => {
    setDrawerOpen(drawerInitialState);
    setNoteAction({ type: null, index: null, note: '' });
  };

  return (
    <>
      <CustomDrawer
        content={
          <NotesListContent
            notesState={notes}
            noteAction={noteAction}
            handleNoteChange={handleNoteChange}
            saveEdit={saveEdit}
            setNoteAction={setNoteAction}
            startEdit={startEdit}
            openDeleteDialog={openDeleteDialog}
            addNotes={handleAddNotes}
          />
        }
        isOpen={drawerOpen.open}
        onClose={handleClose}
        title={
          selectedToothId
            ? `${t('common.tooth')} ${selectedToothId}`
            : t(
                'pages.patients.patient-profile.teeth-schema.actions.notes.select',
              )
        }
      />
      <Dialog
        open={deleteConfirmation.open}
        onClose={() =>
          setDeleteConfirmation({ ...deleteConfirmation, open: false })
        }
      >
        <DialogTitle>
          {t(
            'pages.patients.patient-profile.teeth-schema.actions.notes.delete-modal.confirm-label',
          )}
        </DialogTitle>
        <DialogContent>
          <Text>
            {t(
              'pages.patients.patient-profile.teeth-schema.actions.notes.delete-modal.prompt',
            )}
          </Text>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              setDeleteConfirmation({ ...deleteConfirmation, open: false })
            }
          >
            {t('common.cancel')}
          </Button>
          <Button onClick={confirmDelete} autoFocus>
            {t('common.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
