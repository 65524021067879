import { styled, Box, Divider } from '@mui/material';
import { HOUR_HEIGHT, TIMELINE_COLUMN_WIDTH } from '../../constants';

export const TimelineBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: `${TIMELINE_COLUMN_WIDTH}px`,
  height: `${HOUR_HEIGHT * 24}px`,
});

export const TimelineHourBox = styled(Box)({
  width: `${TIMELINE_COLUMN_WIDTH}px`,
  height: `${HOUR_HEIGHT}px`,
  padding: 0,
  margin: 0,
  display: 'flex',
  flexDirection: 'row',
  textAlign: 'center',
  alignItems: 'flex-start',
});

export const FullHeightDivider = styled(Divider)({
  height: `${HOUR_HEIGHT * 24}px`,
});
