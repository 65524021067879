import { Box } from '@mui/material';
import { styled } from '@mui/system';

const HeaderMenuItemsWrapper = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
}));

export default HeaderMenuItemsWrapper;
