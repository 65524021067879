import { MainLayout, SideDrawer } from '@/components';
import { useAuthContext } from '@/providers';
import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { IRouteProps } from '../types';
import DoctorRoutes from './doctorRoutes.json';

export const DoctorRoute: FC<IRouteProps> = ({ redirectPath }) => {
  const { user } = useAuthContext();

  if (user?.role === 'doctor' && user?.connection === 'online')
    return (
      <>
        <SideDrawer customTabs={DoctorRoutes} />
        <MainLayout>
          <Outlet />
        </MainLayout>
      </>
    );

  return <Navigate to={redirectPath} replace />;
};
