import { CardProps, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';
import { StyledCard } from './styled';

export interface CustomCardProps extends CardProps {
  width?: string | number;
  height?: string | number;
  children: ReactNode;
  hover?: boolean;
  bgColor?: string;
}

export const CustomCard: React.FC<CustomCardProps> = ({
  width,
  height,
  children,
  hover,
  sx,
}) => {
  const theme = useTheme();
  return (
    <StyledCard
      theme={theme}
      sx={sx}
      width={width}
      height={height}
      hover={!!hover}
    >
      {children}
    </StyledCard>
  );
};

CustomCard.defaultProps = {
  width: 400,
  height: 256,
  hover: false,
  bgColor: 'white',
};
